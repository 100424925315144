import React from 'react'

import {GaleryStyle} from '../../../style/utils/galery/style'
const Galery = ({data}) => {
  
  return (
    <div className={GaleryStyle}>
       <div className="galery">
        {data.map((item,i)=>
        <div className="item" key={i}>
          <img src={item.img} alt="" className="desktop"/>
          <img src= {item.imgResponsive} alt="" className="mobile"/>
        </div>
        )}
       </div>
    </div>
  )
}

export default Galery

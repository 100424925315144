import React, {createContext, useState}  from 'react'
const DiamondsContext = createContext()
const { Provider, Consumer } = DiamondsContext

const DiamondsProvider = ({children}) =>  {
    const [ diamonds, setDiamonds] = useState([
      {
        title: 'diamonds.certified.title',
        text : 'diamonds.certified.text',
        image1: './assets/Desktop/Diamonds/es-fggt-diamonds-img2.png',
        image1mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img2.png',
        width: '31%',
        image2:'./assets/Desktop/Diamonds/es-fggt-diamonds-img3.png',
        image2mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img3.png',
        width2: '62%'
      },
      {
        title: 'diamonds.fancy.title',
        text: 'diamonds.fancy.text',
        image1: './assets/Desktop/Diamonds/es-fggt-diamonds-img4.png',
        image1mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img4.png',
        width: '62%',
        image2:'./assets/Desktop/Diamonds/es-fggt-diamonds-img5.png',
        image2mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img5.png',
        width2: '31%'
      },
      {
        title: 'diamonds.rough.title',
        text: 'diamonds.rough.text',
        image1: './assets/Desktop/Diamonds/es-fggt-diamonds-img6.png',
        image1mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img6.png',
        width: '31%',
        image2:'./assets/Desktop/Diamonds/es-fggt-diamonds-img7.png',
        image2mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img7.png',
        width2: '62%'
      },
      {
        title: 'diamonds.selling.title',
        text: 'diamonds.selling.text',
        image1: './assets/Desktop/Diamonds/es-fggt-diamonds-img8.png',
        image1mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img8.png',
        width: '62%',
        image2:'./assets/Desktop/Diamonds/es-fggt-diamonds-img9.png',
        image2mobile:'./assets/Mobile/Diamonds/mo-fggt-diamonds-img9.png',
        width2: '31%'
      },
    ])
    return(
        <Provider
            value={{
              diamonds, setDiamonds
            }}
        >
           {children} 
        </Provider>
    )
}

export { DiamondsProvider, Consumer as DiamondsConsumer, DiamondsContext }
import React, {createContext, useState}  from 'react'
const PreciousGemstonesContext = createContext()
const { Provider, Consumer } = PreciousGemstonesContext

const PreciousGemstonesProvider = ({children}) =>  {
    const [ list, setList] = useState([
      {
        title: 'gemstones.emeralds.title',
        text : 'gemstones.emeralds.text',
        image1: './assets/Desktop/PreciousGemstones/es-fggt-gemstones-img2.png',
        image1mobile:'./assets/Mobile/PreciousGemstones/mo-fggt-gemstones-img2.png',
        width: '62%',
        image2:'./assets/Desktop/PreciousGemstones/es-fggt-gemstones-img3.png',
        image2mobile:'./assets/Mobile/PreciousGemstones/mo-fggt-gemstones-img3.png',
        width2: '31%'
      },
      {
        title: 'gemstones.selling.title',
        text: 'gemstones.selling.text',
        image1: './assets/Desktop/PreciousGemstones/es-fggt-gemstones-img4.png',
        image1mobile:'./assets/Mobile/PreciousGemstones/mo-fggt-gemstones-img4.png',
        width: '31%',
        image2:'./assets/Desktop/PreciousGemstones/es-fggt-gemstones-img5.png',
        image2mobile:'./assets/Mobile/PreciousGemstones/mo-fggt-gemstones-img5.png',
        width2: '62%'
      },

    ])
    return(
        <Provider
            value={{
              list, setList
            }}
        >
           {children} 
        </Provider>
    )
}

export { PreciousGemstonesProvider, Consumer as PreciousGemstonesConsumer, PreciousGemstonesContext }
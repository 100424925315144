import { style } from 'typestyle'
import {Font, Color} from '../var'

export const LogisticStyle = style({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Avenir',
  overflowX:'none',
  $nest: {
    '& .infoLogistic': {
      display: 'flex',
      alignItems:'space-between',
      $nest:{
        '& .logistic': {
          paddingTop:'100px',
          paddingLeft:'100px',
          boxSizing:'border-box',
          paddingBottom:'100px',
          display:'flex',
          justifyContent:'space-between',
          width:'100%',
          $nest:{
            '& .textLogistic':{
              width:'65%',
              fontSize: Font.principal,
              color: Color.mediumGray,
              $nest:{
                '& a':{
                  fontSize: Font.principal,
              color: Color.mediumGray,
                },
                '@media screen and (max-width: 700px)':{
                  width: '100%',
                  fontSize: Font.normal,
                  fontWeight: '100',
                  order: '2'
                }
              }
            },
            '& .iconoLogistic': {
              width:'35%',
              display:'flex',
              justifyContent:'flex-end',
              paddingRight:'100px',
              boxSizing:'border-box',
              alignItems: 'center',
              $nest: {
                width: '100%',
                justifyContent: 'flex-start',
                '@media screen and (max-width: 1024px)':{
                  paddingRight:'0px'
                }
              }
            },
            '@media screen and (max-width: 1024px)':{
              paddingLeft:'0px',
            },
            '@media screen and (max-width: 700px)':{
              paddingTop:'60px',
              paddingBottom:'60px',
               flexDirection: 'column',
               $nest:{
                '& img':{
                  width: '68px',
                  paddingBottom: '60px'
                }
              }
            },

          }
        }
      }
    },
    '& .transportCompany':{
      display: 'flex',
      flexDirection:'column',
      width:'100%',
      paddingBottom:'100px',
      $nest: {
        '& .description':{
          paddingLeft:'100px',
          boxSizing:'border-box',
          width:'100%',
          color: Color.lightGold,
          fontSize: Font.minBig,
          justifyContent:'center',
          $nest:{
            '@media screen and (max-width: 1024px)':{
              paddingLeft:'0px',
              '& br':{
                display:'none'
              },
            },
            '@media screen and (max-width: 600px)':{
              fontSize: Font.intermedium,
            }
          }
        },
        '& .logos':{
          display:'flex',
          alignItems:'center',
          paddingTop:'20px',
          paddingLeft:'100px',
          boxSizing:'border-box',
          width:'100%',
          $nest: {
            '& img':{
              paddingTop: '30px',
              paddingRight:'50px',
              boxSizing:'border-box'
            },
            '@media screen and (max-width: 1024px)':{
              paddingLeft:'0px',
              flexDirection:'column',
              alignItems:'flex-start',
              $nest: {
                '& img':{
                  maxWidth:'250px'
                }
              }
            }
          }
        }
      }
    }
  }
})
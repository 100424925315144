import React, { useContext } from 'react'
//Components
import Banner from '../utils/banner'
import List from '../utils/list'
import CallToAction from '../utils/callToAction'
//Context
import {PreciousMetalsContext}from '../../context/preciousMetals'

const PreciousMetals = ()=> {
  const {
    list
  } = useContext(PreciousMetalsContext)
  return (
    <div>
      <Banner
        img = './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img1.png'
        imgResponsive = './assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img1.png'
        title = 'preciousMetals.title'
      />
      <List
        data = {list}
      />
      <CallToAction/>
    </div>
  )
}

export default PreciousMetals

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext } from 'react'
import Banner from '../utils/banner'
import parse from 'html-react-parser'
//Context
import{ComplianceContext} from '../../context/compliance'
//Style
import {container} from '../../style/var'
import {ComplianceStyle} from '../../style/compliance/style'
//Swipper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper';
import {useTranslation} from 'react-i18next'

const Compliance = () => {
  const {t} = useTranslation()
  const{
    list,
    icon
  } = useContext(ComplianceContext)
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,EffectFade,Autoplay])
  return (
    <div className={ComplianceStyle}>
      <Banner
        img ='./assets/Desktop/Compliance/es-fggt-compliance-img1.png'
        imgResponsive= './assets/Mobile/Compliance/mo-fggt-compliance-img1.png'
      />

      <div className={`${container} compliance`}>
        <div className="titleCompliance">{t('compliance.title')}</div>
        {list.map((item,i)=> 
        <div className="info" key={i}>
          <div className="line">
            <svg height="8" width="122">
              <line x1="0" y1="0" x2="122" y2="0" style={{stroke:'#333333',strokeWidth:2}} />
            </svg>
          </div>
          <div className="text">
            <div className="infoTitle">{t(item.title)}</div>
            <div className="infoDescription">
              {parse(t(item.text))}
            </div>
          </div>
        </div>
        )}
      </div>
      <div className={`${container} galery`}>
      <Swiper
        spaceBetween={0}
        slidesPerView={4}
        navigation
        //pagination={{ clickable: true }}
        autoplay = {{ delay: 3000}}
        className= 'swiperContent desktop'
      >
         {icon.map((item,i)=>
        <SwiperSlide data-swiper-autoplay="5000"  key={i}>
            <a href={item.link} target="_blank"className="link" >
              <img src={item.image} alt="image" />
            </a>
        </SwiperSlide>
        )}
      </Swiper>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        //pagination={{ clickable: true }}
        autoplay = {{ delay: 3000}}
        className= 'swiperContent mobile'
      >
         {icon.map((item,i)=>
        <SwiperSlide data-swiper-autoplay="5000"  key={i}>
          <a href={item.link} target="_blank" className="info" >
            <img src={item.image} alt="image" className="link"/>
          </a>
        </SwiperSlide>
        )}
      </Swiper>
      </div>
    </div>
  )
}
export default Compliance
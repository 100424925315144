import React, { useContext } from 'react'
//Components
import Banner from '../utils/banner'
import List from '../utils/list'
import CallToAction from '../utils/callToAction'
import parse from 'html-react-parser'
//Context
import {PreciousGemstonesContext}from '../../context/preciousGemstones'
//Style
import {PreciousGemstonesStyle} from '../../style/preciousGemstones/style'
import {container} from '../../style/var'
//lang
import {useTranslation} from 'react-i18next'

const PreciousGemstones = () => {
  const {t} = useTranslation()
  const{
    list
  } = useContext(PreciousGemstonesContext)
  return (
    <div className={PreciousGemstonesStyle}>
      <Banner
        img ='./assets/Desktop/PreciousGemstones/es-fggt-gemstones-img1.png'
        imgResponsive= './assets/Mobile/PreciousGemstones/mo-fggt-gemstones-img1.png'
        title = 'gemstones.title'
      />
      <div className="infoGemstones">
        <div className={`${container} textGemstones`}>
         {parse(t('gemstones.info'))} 
        </div>
      </div>
      <List 
        data = {list}
      />
      <CallToAction/>
    </div>
  )
}
export default PreciousGemstones
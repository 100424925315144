import {style} from 'typestyle'
import {Color, Font} from '../var'

export const ContactStyle = style({
  width :'100%',
  fontFamily: 'Avenir',
  $nest: {
    '& .form': {
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding:'100px 0',
      $nest: {
        '& .border':{
          padding: '60px 40px',
          border: '2px solid',
          borderColor: Color.lightGray,
          borderRadius: '25px',
          $nest: {
            '& .content': {
              width:'100%',
              display: 'grid',
              gridTemplateColumns:'repeat(2,1fr)',
              rowGap: '40px',
              $nest: {
                '& .item':{
                  display: 'flex',
                  flexDirection:'column',
                  $nest: {
                    '@media screen and (max-width: 700px)':{
                      justifyContent:'center',
                      alignItems: 'center'
                    }
                  }
                },
                '@media screen and (max-width: 1024px)':{
                  columnGap:'40px'
                 },
                 '@media screen and (max-width: 768px)':{
                  columnGap:'10px'
                 },
                '@media screen and (max-width: 700px)':{
                 display: 'flex',
                 flexDirection: 'column',
                 rowGap: '25px',
                 columnGap:'0'
                },
              }
            },
            '& .comment': {
              gridColumn:'1/3',
            },
            '& .buttons':{
              gridColumn:'2/3',
              width:'100%',
              paddingTop: '20px',
              paddingRight:'40px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection:'row',
              justifyContent: 'flex-end',
              $nest: {
                '& .cancel': {
                  marginRight: '54px',
                  minWidth: '200px',
                  $nest:{
                    '@media screen and (max-width: 1024px)':{
                      minWidth: '146px',
                      marginRight: '10px',
                    },
                    '@media screen and (max-width: 320px)':{
                      minWidth: '120px',
                      marginRight: '10px',
                    }
                  }
                },
                '& .send':{
                  backgroundColor: Color.orange,
                  color: Color.white,
                  minWidth: '200px',
                  $nest:{
                    '@media screen and (max-width: 1024px)':{
                      minWidth: '146px',
                    },
                    '@media screen and (max-width: 320px)':{
                      minWidth: '120px',
                      marginRight: '10px',
                    }
                  }
                },
                '@media screen and (max-width: 700px)':{
                  paddingRight:'0px',
                }
              }
            },
            '@media screen and (max-width: 1024px)':{
              width:'80%'
            },
            '@media screen and (max-width: 700px)':{
              padding: '0px 37px',
              border: 'none'
            }
          }
        },
        '@media screen and (max-width: 700px)':{
          padding:'60px 0',
        }
      }
    },
    '& label':{
      marginBottom:'7px',
      fontSize:Font.normal,
      color: Color.black,
      fontWeight: 'bold',
      $nest:{
        '@media screen and (max-width: 700px)':{
          alignSelf:'baseline'
        }
      }
    },
    '& input[type="text" i] ':{
      width: '454px',
      height: '40px',
      border: '1.5px solid',
      borderColor: Color.lightGold,
      $nest: {
        '@media screen and (max-width: 920px)':{
          width: '300px',
        },
        '@media screen and (max-width: 1024px)':{
          width: '380px',
        },
      }
    },
    '& input[type="text" i]:focus':{
      outlineColor:Color.lightGray
    },
    '& textarea': {
      width:'95%',
      height:'100px',
      border: '1.5px solid',
      borderColor: Color.lightGold,
      resize:'none',
      $nest: {
        '@media screen and (max-width: 1024px)':{
          width:'98%'
        },
        '@media screen and (max-width: 834px)':{
          width:'92%',
          $nest: {
            '@media screen and (max-width: 700px)':{
              width:'300px'
            },
          }
        },
      }
    },
    '& textarea:focus':{
      outlineColor:Color.lightGray
    }
  }
})
import React, {createContext, useState}  from 'react'
const FormContext = createContext()
const { Provider, Consumer } = FormContext

const FormProvider = ({children}) =>  {
    const[name,setName] = useState('')
    const[phone,setPhone] = useState('')
    const[email,setEmail] = useState('')
    const[company,setCompany] = useState('')
    const[activity,setActivity] = useState('')
    const[individual,setIndividual] = useState('')
    const[registratrionNumber,setRegistratrionNumber] = useState('')
    const[address,setAddress] = useState('')
    const[city,setCity] = useState('')
    const[country,setCountry] = useState('')
    const [subject,setSubject] = useState('')
    const [item, setItem]=useState({})

    return(
      <Provider
          value={{
            name,setName,
            phone,setPhone,
            email,setEmail,
            company,setCompany,
            activity,setActivity,
            individual,setIndividual,
            registratrionNumber,setRegistratrionNumber,
            address,setAddress,
            city,setCity,
            country,setCountry,
            subject,setSubject,
            item, setItem
          }}
        >
        {children}
      </Provider>
    )
}

export { FormProvider, Consumer as FormConsumer, FormContext }
import  { style } from 'typestyle'
import {Font} from '../../var'

export  const BannerStyle = style({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Avenir',
  paddingTop:'140px',
  $nest: {
    '& .desktop': {
      height: '50vh',
      backgroundSize:'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      width: '100%',
      display:'flex',
      backgroundPosition:'center',
      justifyContent:'center',
      alignItems:'center',
      $nest: {
        '& img':{
          position: 'absolute'
        },
        '@media screen and (max-width: 700px)':{
          display: 'none'
        }
      }
    },
    '& .mobile': {
      height: '50vh',
      backgroundSize:'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      width: '100%',
      display: 'none',
      $nest: {
        '@media screen and (max-width: 700px)':{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
        },
        '& img': {
          width:'30%'
        }
      }
    },
    '& .title': {
      width: '100%',
      $nest:{
        '@media screen and (max-width: 768px)':{
          fontSize: Font.intermedium
        } ,
        '@media screen and (max-width: 375px)':{
          fontSize: Font.principal
        }
      }
    },
    '@media screen and (max-width: 834px)':{
      paddingTop:'80px',
    }
  }
})
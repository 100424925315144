import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
//Style
import {CallToActionStyle} from '../../../style/utils/callToAction/style'
import {container, button} from '../../../style/var'
//context
import {CallToActionContext} from '../../../context/callToAction'
import {FormContext} from '../../../context/form'
//lang
import { useTranslation } from 'react-i18next'

const CallToAction = () => {
  const { t } = useTranslation()
  const{
    card
  } = useContext(CallToActionContext)

  const {
    setSubject,
    setItem
  } = useContext(FormContext)

  
  return (
    <div className={CallToActionStyle}>
      <div className={`${container} info`}>
        <div className="line">
          <svg height="8" width="122">
            <line x1="0" y1="0" x2="122" y2="0" style={{stroke:'#333333',strokeWidth:2}} />
          </svg>
        </div>
        <div className="title">
          {t('callToAction.title')}
        </div>
      </div>
      <div className={`${container}`}>
        <div className='cards'>
        {card.map((item,i)=>
        <div className="item" key = {i}>
          <div className="icon">
              <img src={item.icon} alt="Gold Icon"/>
          </div>
          <div className="titleCard">
            {t(item.title)}
          </div>
          <div className= {button} onClick={() => {
            setSubject(t(item.title))
            setItem(item)
            window.scrollTo(0, 0,)
          }}>
            <Link to= {item.link} >
              {t('callToAction.button')}
            </Link>
          </div>
        </div>
        )}
        </div>
      </div>
    </div>
  )
}

export default CallToAction

import { en } from './en'
import { es } from './es'
import { fr } from './fr'

export const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  fr: {
    translation: fr
  },
}
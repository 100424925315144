import React, {createContext, useState}  from 'react'
const HomeContext = createContext()
const { Provider, Consumer } = HomeContext

const HomeProvider = ({children}) =>  {
    const [ company, setCompany] = useState([
      {
        title: 'home.company.whatDo.title',
        text : 'home.company.whatDo.text'
      },
      {
        title: 'home.company.commitment.title',
        text: 'home.company.commitment.text'
      },
      {
        title: 'home.company.values.title',
        text: 'home.company.values.text'
      }
    ])
    const[ galery, setGalery ] = useState([
      {img: './assets/Desktop/Home/es-fggt-home-img1.png', imgResponsive:'./assets/Mobile/Home/mo-fggt-home-img1.png'},
      {img: './assets/Desktop/Home/es-fggt-home-img2.png', imgResponsive:'./assets/Mobile/Home/mo-fggt-home-img2.png'},
      {img: './assets/Desktop/Home/es-fggt-home-img3.png', imgResponsive:'./assets/Mobile/Home/mo-fggt-home-img3.png'}
    ])

    return(
        <Provider
            value={{
              company, setCompany,
              galery, setGalery
            }}
        >
           {children} 
        </Provider>
    )
}

export { HomeProvider, Consumer as HomeConsumer, HomeContext }
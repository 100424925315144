import React, {useContext} from 'react'
//Component
import Banner from '../utils/banner'
import Form from '../utils/form'
//Style
//Context
import {FormContext} from '../../context/form'

const FormSupplier = () => {
  const{
    item
  } = useContext(FormContext)

  console.log(item)
  return (
    <>
      <Banner
        img ={item.banner.img}
        imgResponsive= {item.banner.imgResponsive}
        title = {item.banner.title}
      />
      <Form/>
    </>
  )
}

export default FormSupplier

import {style} from 'typestyle'
import {Color, Font} from '../var'

export const ComplianceStyle = style({
  width: '100%',
  fontFamily:'Avenir',
  $nest: {
    '& .compliance': {
      display: 'flex',
      flexDirection: 'column',
      margin: '100px 0',
      $nest: {
        '& .titleCompliance':{
           color: Color.lightGold,
           fontSize:Font.MegaBig,
           textTransform: 'uppercase'
        },
        '& .info':{
          width: '100%',
          display: 'flex',
          marginTop: '29px',
          marginBottom: '20px',
          $nest: {
            '& .line': {
             marginTop: '10px'
            },
           '& .text': {
             marginLeft:'16px',
             width: '70%',
             $nest: {
               '& .infoTitle': {
                 fontSize: Font.medium,
                 fontWeight: 'bold',
                 color: Color.black,
                 $nest: {
                   '@media screen and (max-width: 700px)':{
                     fontSize: Font.principal
                   }
                 }
               },
               '& .infoDescription': {
                 marginTop: '34px',
                 fontSize: Font.principal,
                 color: Color.mediumGray,
                 $nest:{
                  '& div':{
                    display:'flex',
                    justifyContent:'flex-end',
                    color: Color.lightGold,
                    $nest:{
                      '& ul>li>a':{
                          textDecoration:'underline',
                          fontWeight:'bold',
                          color: Color.lightGold,
                          $nest: {
                            '@media screen and (max-width: 700px)':{
                              display:'block',
                              paddingBottom:'10px'
                            }
                          }
                      }
                    }
                  },
                  '@media screen and (max-width: 700px)':{
                    fontSize: Font.normal
                  }
                 }
               },
               '@media screen and (max-width: 700px)':{
                 marginLeft: '0px',
                 width: '100%',
               }
             }
           }
          }
        }
      }
    },
    '& .galery':{
      marginBottom:'100px',
      display: 'flex',
      justifyContent:'center',
      alignItems:'center',
      $nest:{
        '& .swiperContent':{
          position: 'relative',
          $nest:{
            '& .link':{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              overflow: 'hidden',
              height:'200px',
              position: 'relative',
              width:'90%',
            }
        },
      },
      '& .desktop':{
        display: 'block',
        $nest: {
          '@media screen and (max-width: 700px)':{
            display:'none'
          }
        }
      },
      '& .mobile': {
        display:'none',
        $nest: {
          '@media screen and (max-width: 700px)':{
            display:'flex',
          },
          '& .swiper-slide':{
            width:'90%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'200px',
            $nest:{
              '& .link':{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                overflow: 'hidden',
                height:'auto',
                position: 'relative',
                width:'100%',
              }
            }
          }
        }
      }
      },
    },
    '@media screen and (max-width: 700px)':{
      $nest: {
        '& .compliance': {
          margin: '60px 0',
          $nest: {
            '& .titleCompliance':{
              fontSize:Font.medium,
            }
          }
        },
        '& .info':{
          marginTop: '23px',
          $nest: {
            '& .line':{
              display:'none'
            },
          }
        }
      }
    }
  }
})
import React, {createContext, useState}  from 'react'
const JewelryContext = createContext()
const { Provider, Consumer } = JewelryContext

const JewelryProvider = ({children}) =>  {
    const [ list, setList] = useState([
      {
        title: 'jewerly.design.title',
        text : 'jewerly.design.text'
      },
      {
        title: 'jewerly.hight.title',
        text: 'jewerly.hight.text'
      }
    ]
    )
    return(
        <Provider
            value={{
              list, setList,
            }}
        >
           {children} 
        </Provider>
    )
}

export { JewelryProvider, Consumer as JewelryConsumer, JewelryContext }
import React, { createContext, useState } from "react";
const MenuContext = createContext();
const { Provider, Consumer } = MenuContext;

const MenuProvider = ({ children }) => {
  const [menu, setMenu] = useState([
    { label: "menu.home", link: "/home", viewLinks: ["home", "/"] },
    { label: "menu.about", link: "/about", viewLinks: ["about"] },
    {
      label: "menu.metals",
      link: "/PreciousMetals",
      viewLinks: ["PreciousMetals"],
    },
    {
      label: "menu.nanoMetals",
      link: "/nanoMetals",
      viewLinks: ["nanoMetals"],
    },
    { label: "menu.diamonds", link: "/diamonds", viewLinks: ["diamonds"] },
    {
      label: "menu.gemstones",
      link: "/preciousGemstones",
      viewLinks: ["preciousGemstones"],
    },
    { label: "menu.jewerly", link: "/jewerly", viewLinks: ["jewerly"] },
    {
      label: "menu.compliance",
      link: "/compliance",
      viewLinks: ["compliance"],
    },
    { label: "menu.logistic", link: "/logistics", viewLinks: ["logistics"] },
    { label: "menu.contact", link: "/contact", viewLinks: ["contact"] },
  ]);
  const [ language, setLanguage ] = useState('en')
  return (
    <Provider
      value={{
        menu,
        setMenu,
        language, setLanguage
      }}
    >
      {children}
    </Provider>
  );
};

export { MenuProvider, Consumer as MenuConsumer, MenuContext };

import { style } from "typestyle";
import { Color, Font } from "../var";

export const NanoMetalsStyle = style({
  width: "100%",
  fontFamily: "Avenir",
  $nest: {
    "& .tableInfo": {
      display: "flex",
      marginTop: "100px",
      marginBottom: "100px",
      $nest: {
        "& .line": {
          marginTop: "10px",
          $nest: {
            "@media screen and (max-width: 700px)": {
              display: "none",
            },
          },
        },
        "& .text": {
          marginLeft: "16px",
          width: "90%",
          color: Color.black,
          $nest: {
            "& .infoTitle": {
              fontSize: Font.medium,
              fontWeight: "bold",
              color: Color.black,
            },
            "& table, th,td": {
              border: `2px solid ${Color.darkGold}`,
              borderCollapse: "collapse",
              padding: "10px",
              boxSizing: "border-box",
              color: Color.black,
              borderRadius: '40px'
            },
           /*  "& table":{
              borderSpacing: 0, 
            },
            "& .table_container":{
              border: `2px solid ${Color.darkGold}`,
              borderRadius: '20px'
            },
            '&  th,td':{
              border: `2px solid ${Color.darkGold}`,
              borderCollapse: "collapse",
            },
            '& th:first-child':{
              borderRadius: '17px 0 0 0',
              borderCollapse: "collapse",
            },
            '& th:last-child ':{
              borderRadius: '0 17px 0 0',
              borderCollapse: "collapse",
            },
            '& th:only-child':{
              borderRadius: '20px 20px 10px 0',
              borderCollapse: "collapse",
            }, */
            /* "& table tr:first-child td:first-child": {
              borderRadius: "20px 0 0 0",
            }, */
            "& .galeryProducts":{
              marginTop: "100px",
              marginBottom: "100px",
              width: "100%",
              $nest: {
                '& .galeryTitle':{
                  color: Color.lightGold,
                },
                '& .galeryContainers':{
                  marginTop: "58px",
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7,1fr)',
                  $nest: {
                    '& .item':{
                      height: "155px",
                      border: '1px solid',
                      borderColor: Color.lightGold,
                      borderCollapse: "collapse",
                      $nest:{
                        '@media screen and (max-width: 700px)':{
                          gridTemplateColumns: 'repeat(2,1fr)',
                        }
                      }
                    },
                  }
                }
              }
            },
            '& .table_mobile':{
              display: "none"
            }
          },
        },
      },
    },
    '@media screen and (max-width: 700px)':{
      width: "100%",
      $nest:{
        "& .tableInfo":{
          flexDirection: 'column',
          margin: '50px auto',
          $nest:{
            '& .text':{
              marginLeft: "0px",
              $nest:{
                '& table':{
                  display: "none"
                },
                '& .table_mobile':{
                  display: "block",
                  $nest: {
                    '& h4':{
                      color: Color.darkGold,
                    },
                    '& li':{
                      color: Color.black,
                    }
                  }
                },
                '& .disclaimer':{
                  display: 'none',
                },
                "& .galeryProducts":{
                  marginTop: "50px",
                  marginBottom: "50px",
                  $nest: {
                    '& .galeryContainers':{
                      gridTemplateColumns: 'repeat(2,1fr)',
                      $nest: {
                        '& .item':{
                          height: '125px'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
});

import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import Home from '../home'
import About from '../about'
import PreciousMetals from '../preciousMetals'
import Diamonds from '../diamonds'
import PreciousGemstones from '../preciousGemstones'
import Compliance from '../compliance'
import Contact from '../contact'
import FormSupplier from '../FormSupplier'
import Jewerly from '../jewerly'
import Logistic from '../logistic'
import NanoMetals from '../nanoMetals'

const Menu = () => {
  return (
    <Switch>
      <Route exact path="/"><Home/></Route>
      <Route exact path="/home"><Home/></Route>
      <Route exact path="/about"><About/></Route>
      <Route exact path="/preciousMetals"><PreciousMetals/></Route>
      <Route exact path="/diamonds"><Diamonds/></Route>
      <Route exact path="/preciousGemstones"><PreciousGemstones/></Route>
      <Route exact path="/jewerly"><Jewerly/></Route>
      <Route exact path="/compliance"><Compliance/></Route>
      <Route exact path="/contact"><Contact/></Route>
      <Route exact path="/logistics"><Logistic/></Route>
      <Route exact path="/formSupplier"><FormSupplier/></Route>
      <Route exact path="/nanoMetals"><NanoMetals/></Route>
      
    </Switch>
  )
}

export default Menu
import {style} from 'typestyle'
import {Color, Font} from '../../var'

export const CallToActionStyle = style({
  width: '100%',
  fontFamily: 'Avenir',
  backgroundColor: Color.lightOrange,
  $nest: {
    '& .info':{
      width: '100%',
      display: 'flex',
      paddingTop: '75px',
      paddingBottom: '0',
      $nest: {
        '& .line': {
         marginTop: '10px',
         $nest:{
           '@media screen and (max-width: 700px)':{
             display:'none'
           }
         }
        },
       '& .title': {
         marginLeft:'16px',
         width: '90%',
         fontSize: Font.medium,
         color: Color.black,
         fontWeight: 'bold'
        }
      }
    } ,
    '& .cards':{
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)', 
      paddingBottom: '75px',
      columnGap: '10px',
      $nest: {
        '& .item': {
          margin: '65px 0px 0',
          padding: '60px',
          borderRadius: '25px',
          border: '1.5px solid',
          borderColor: Color.lightGray,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          $nest: {
            '& .icon':{
              width: '122px',
              height: '122px',
              borderRadius: '50%',
              backgroundColor: Color.lightGold,
              display: 'flex',
              justifyContent:'center',
              $nest: {
                '& img':{
                  width:'70%'
                }
              }
            },
            '& .titleCard': {
              margin: '30px 0',
              height: '70px',
              fontSize: Font.medium,
              fontWeight: 'bold',
              textAlign: 'center',
              display: 'flex',
              justifyContent:'center',
              alignItems:'center',
              $nest: {
                '@media screen and (max-width: 1440px)':{
                  fontSize:Font.intermedium,
                  $nest:{
                    '@media screen and (max-width: 700px)':{
                      fontSize:Font.principal,
                      height:'40px'
                    }
                  }
                },
              }
            },
            '@media screen and (max-width: 1024px)':{
              width:'35%',
              $nest:{
                '@media screen and (max-width: 700px)':{
                  width:'100%'
                }
              }
            },
          }

        },
        '@media screen and (max-width: 1024px)':{
          display:'flex',
          flexWrap:'wrap',
          justifyContent: 'center',
          $nest:{
            '@media screen and (max-width: 700px)':{
              gridTemplateColumns: 'repeat(1,1fr)', 
            }
          }
        },

      }
    },
    '& a' : {
      color: Color.lightGold,
      textDecoration: 'none'
    }
  }

})
import React, {createContext, useState}  from 'react'
const NanoMetalsContext = createContext()
const { Provider, Consumer} = NanoMetalsContext

const NanoMetalsProvider = ({children}) => {
  const [ list,setList ] = useState([
    {
      title: 'nanoMetals.nannoCopper.title',
      text : 'nanoMetals.nannoCopper.text',
      image1: './assets/Desktop/nanoMetals/es-fggt-nanometals-img2.png',
      image1mobile:'./assets/Mobile/nanoMetals/mo-fggt-nanometals-img2.png',
      width: '62%',
      image2:'./assets/Desktop/nanoMetals/es-fggt-nanometals-img3.png',
      image2mobile:'./assets/Mobile/NanoMetals/mo-fggt-nanometals-img3.png',
      width2: '31%'
    },
    {
      title: 'nanoMetals.process.title',
      text : 'nanoMetals.process.text',
      image1: './assets/Desktop/nanoMetals/es-NM-paso1.png',
      image1mobile:'./assets/Mobile/NanoMetals/mo-NM-pasos.gif',
      width: '22%',
      titleImage1: 'nanoMetals.process.titleImage1',
      image2:'./assets/Desktop/nanoMetals/es-NM-paso2.png',
      titleImage2: 'nanoMetals.process.titleImage2',
      width2: '22%',
      image3:'./assets/Desktop/nanoMetals/es-NM-paso3.png',
      titleImage3: 'nanoMetals.process.titleImage3',
      width3: '22%',
      image4:'./assets/Desktop/nanoMetals/es-NM-paso4.png',
      titleImage4: 'nanoMetals.process.titleImage4',
      width4: '22%',
    },
  ])

  return(
    <Provider
      value={{
        list, setList
      }}
    >
      {children}
    </Provider>
  )
}

export {  NanoMetalsProvider, Consumer as NanoMetalsConsumer, NanoMetalsContext}
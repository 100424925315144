import { style } from "typestyle";

import { Color } from '../../../../style/var'

export const message = {
  cont: style({
    backgroundColor: Color.darkGold,
    color: Color.white,
    borderColor: Color.darkGold,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: '10px',
    zIndex: 100000,
    padding: '5px',

    $nest: {
      '&.success': {
        backgroundColor: Color.darkGold,
        color: Color.white,
        borderColor: Color.white,
        borderStyle: 'solid',
        borderWidth: '1px'
      }
    }
  }),

  contClose: style({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: '5px',
    marginRight: '5px',

    $nest: {
      '& .close': {
        color: Color.black,
        position: 'absolute',
        cursor: 'pointer',

        $nest: {
          '&.success': {
            color: Color.white,

          }
        }
      }
    }
  }),

  contMessage: style({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '7px',
    alignItems: 'center',

    $nest: {
      '& .icon': {
        fontSize: '20px',
        margin: '5px'
      }
    }
  })
}
import React, {useContext} from 'react'

//Context
import {FormContext} from '../../../context/form'
//Styles
import {button} from '../../../style/var'
import {FormStyle} from '../../../style/utils/form/style'
import {validateGlobal, toastMessage} from '../validateGlobal'
//api
import {createSupplier} from '../../../api/contact'

const Form = () => {
  const {
    name,setName,
    phone,setPhone,
    email,setEmail,
    company,setCompany,
    activity,setActivity,
    registratrionNumber,setRegistratrionNumber,
    address,setAddress,
    city,setCity,
    country,setCountry,
    subject
  } = useContext(FormContext)
  const cancel = ()=> {
    setName('')
    setPhone('')
    setEmail('')
    setActivity('')
    setCompany('')
    setRegistratrionNumber('')
    setAddress('')
    setCity('')
    setCountry('')
  }
  const sendInfo = e => {
    e.preventDefault()
    const data = {name,phone,email,company,activity,registratrionNumber,address,city,country,subject}
    const [validate] = validateGlobal(
      {
        name:'Require|String',
        phone:'Require|String',
        email:'String',
        activity:'Require|String',
        company: 'String',
        registratrionNumber: 'String',
        address: 'Require|String',
        city: 'Require|String',
        country:'Require|String',
        subject: 'Require|String'
      },
      data,
      {
        message: {
          name: 'The name is require',
          phone: 'The phone is require',
          activity:'The activity is require',
          address:'The address is require',
          city:'The city is require',
          country:'The country is require',
          ErrorDefault: 'Uy algo salio mal...',
          CorrectValidate: ''
        },
        position:'bottom-right',
        correctValidate: false
      },
    )
    if(validate){
      //console.log(validate.name)
      createSupplier(data).then(
        ()=> {
          setName('')
          setPhone('')
          setEmail('')
          setActivity('')
          setCompany('')
          setRegistratrionNumber('')
          setAddress('')
          setCity('')
          setCountry('')
          toastMessage({message: 'Muy pronto estaremos en contacto, Gracias por contar con nosotros' , position: 'bottom-right'})
        }
      ).catch(() =>{
        toastMessage({
          message: 'Uy algo salio mal...',
          position: 'bottom-right', error: true
        })
      })
    }
  }
  return (
    <div className={FormStyle}>
      <div className="form" autoComplete='off'>
        <div className="border">
          <form onSubmit={sendInfo} >
          <div className="content">
            <div className="item">
              <label >Name of contact(*)</label>
              <input type="text" value={name} onChange={ e => setName(e.target.value)} />
            </div>
            <div className="item">
              <label for="phoneNumber" >Phone number(*)</label>
              <input type="text" value={phone} onChange= {e => setPhone(e.target.value)} />
            </div>
            <div className="item">
              <label>E-mail</label>
              <input type="text"  value={email} onChange={ e=> setEmail(e.target.value)} />
            </div>
            <div className="item">
            <label >Choose activity(*)</label>
              <select value={activity} onChange={e => setActivity(e.target.value)} >
                <option value="" selected>Seleccionar</option>
                <option value="miner">Miner</option>
                <option value="trader">Trader</option>
                <option value="supplier">Supplier</option>
                <option value="individual">Individual</option>
              </select>
            </div>
            <div className="item">
              <label>Company</label>
              <input type="text" value={company} onChange= {e => setCompany(e.target.value)} />
            </div>
            <div className="item">
              <label>Registration number</label>
              <input type="text" value = {registratrionNumber} onChange= {e => setRegistratrionNumber(e.target.value)} />
            </div>
            <div className="item">
              <label>Address(*)</label>
              <input type="text" value={address} onChange= {e => setAddress(e.target.value)} />
            </div>
            <div className="item">
              <label>City(*)</label>
              <input type="text" value={city} onChange={e => setCity(e.target.value)} />
            </div>
            <div className="item">
              <label >Country(*)</label>
              <input type="text" value = {country} onChange= {e => setCountry(e.target.value)} />
            </div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="buttons">
            <div className={`${button} cancel`} onClick= {()=> cancel()}>Cancel</div>
            <button  type="submit" className={`${button} send`} >Send</button>
          </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form

import { style } from 'typestyle'
// Style
import { Color, Font } from '../var'

export const menuStyle = style({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Avenir',
  height:'140px',
  zIndex: '10',
  position:'fixed',
  backgroundColor:Color.white,
  borderBottom:'2px solid',
  borderColor:Color.mediumGold,
    $nest: {
    '& .contMenu': {
      display: 'flex',
      $nest: {
        '& .logo': {
          width: '20%',
          $nest:{
            '& .desktop':{
              display:'block',
              width: '180px',
              $nest:{
                'img':{
                  width:'100%'
                },
                '@media screen and (max-width: 834px)':{
                  display: 'none'
                }
              }
            },
            '& .mobile':{
              display:'none',
              $nest:{
                '@media screen and (max-width: 834px)':{
                  display: 'block',
                },
                'img':{
                  width:'100%'
                },
              }
            },
            '@media screen and (max-width: 834px)':{
              display: 'flex',
              alignItems: 'center',
              padding: '0 37px'

            }
          }
        },
        '& .navLinks': {
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          $nest: {
           '& .top': {
             flex: 1,
             display: 'flex',
             justifyContent: 'flex-end',
             alignItems: 'center',
             $nest:{
               '& .item':{
                 margin: '0 80px',
                 display: 'flex',
                 $nest: {
                   '& .btn': {
                     margin: '0 16px',
                     minWidth: '122px !important',
                     $nest: {
                       '@media screen and (max-width: 1024px)':{
                          fontSize:Font.normal
                       }
                     }
                   }
                 }
               },
               '& .idiom':{
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 fontSize: Font.normal,
                 color: Color.black,
                 fontWeight: '600',
                 $nest: {
                   '& img': {
                     marginRight: '8px'
                   },
                   '& span':{
                     marginLeft:'5px',
                     cursor: 'pointer'
                   },
                   '@media screen and (max-width: 1440px)':{
                     fontSize: Font.normal
                   }
                 }
               },
               '@media screen and (max-width: 834px)':{
                 flex: 'initial',
                 display:'none'
               }
             }
           },
           '& .buttom': {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            $nest: {
              '& a': {
                fontSize: Font.normal,
                color: Color.mediumGray,
                marginLeft: '15px',
                textDecoration: 'none',
                textAlign:'center',
                $nest: {
                  '& :active': {
                    color: Color.mediumGray,
                  },
                  '@media screen and (max-width: 1440px)':{
                    fontSize: Font.normal,
                    $nest:{
                      '@media screen and (max-width: 1280px)':{
                        marginLeft:'15px'
                      },
                      '@media screen and (max-width: 1024px)':{
                        marginLeft:'10px',
                        textAlign:'center'
                      },
                      '@media screen and (max-width: 834px)':{
                        fontSize: Font.titleMenu,
                        lineHeight: '1.8',
                        textAlign:'right'
                      },
                      '@media screen and (max-width: 360px)':{
                        fontSize: Font.principal,
                      }
                    }
                  },
                }
              },
              '@media screen and (max-width: 1024px)':{
                alignItems:'center'
              },
              '@media screen and (max-width: 834px)':{
                flex: 'initial',
                flexDirection: 'column',
                justifyContent:'flex-end',
                $nest: {
                  '& a':{
                    fontSize: '25px',
                    color:Color.white,
                    textTransform:'uppercase',
                    lineHeight:'36px',
                    width:'100%',
                    textAlign:'right',
                    padding:'0px 22px',
                    boxSizing:'border-box',
                  }
                }
              },
              '& .desktop':{
                display:'flex',
                $nest:{
                  '@media screen and (max-width: 834px)':{
                    display:'none'
                  }
                }
              },
              '& .inactive': {
                display:'none'
              },
              '& .active':{
                display:'flex'
              }
            }
          },
          '& .buttom .active':{
            $nest:{
              display:'flex',
            }
          },
          }
        },
        '& .activeMenu':{
            display:'flex',
            flexDirection: 'column',
            width:'100%',
            height: '100vh',
            backgroundColor:Color.lightGold,
            zIndex:'100',
            position:'fixed',
            padding: '40px 37px 50px 37px',
            boxSizing:'border-box',
            alignItems: 'center',
            fontSize:'25px',
            lineHeight:'1.4',
            justifyContent:'space-between',
            overflowY: 'hidden',
            top: 0 ,
            $nest: {
              '& .idiom':{
                width:'100%',
                color: Color.white,
                fontWeight:'100',
                fontSize: Font.principal
              },
              '& .options':{
                display:'flex',
                flexDirection:'column',
                fontWeight:'300',
              },
              '.new':{
                width: '100%'
              }
            }
        },
        '& .contIcon': {
          flexDirection: 'row',
          display: 'none',
          order: 1,
          $nest: { 
            '@media screen and (max-width: 834px)': {
            display: 'flex',
            fontSize: '25px',
            zIndex:'101'
            },
            '& .hamburgerIcon': {
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              position: 'relative',
              zIndex: '101',
              margin:'10px 37px ',
              $nest: {
                '& .lines': {
                    position: "absolute",
                    top: '50%',
                    left: '50%',
                    transform: 'translate (-50%, -50%)',
                    height: '3px',
                    width: '50%',
                    backgroundClip: 'padding-box',
                    backgroundColor: Color.black,
                    transition: ' all 0.5s cubic-bezier(0.86, 0, 0.07, 1)'
                },
                '& .lines:nth-child(1)':{
                    transform:' translate(-50%, calc(-50% - 8px))',
                },
                '& .lines:nth-child(2)':{
                    transform:' translate(-50%, calc(-50% + 0px))',
                },
                '& .lines:last-child': {
                    transform: 'translate(-50%, calc(-50% + 8px))'
                },
              }
             },
             '& .active' :{
              '& .lines':{
                background: Color.white,
              },
              '& .lines:nth-child(1)'  : {
                  transform:' translate(-50%, -50%) rotate(45deg)',
              },
              '& .lines:nth-child(2)': {
                  opacity: 0
              },
              '& .lines:nth-child(3)': {
                  transform:'translate(-50%, -50%) rotate(-45deg)',

              }
          },

          }
        },
        '@media screen and (max-width: 834px)':{
          justifyContent:'space-between',
          paddingLeft:'0px',
          paddingRight: '0px'
        }
      }
    },
    '& .buttonsResponsive':{
      display:'none',
      $nest: {
        '@media screen and (max-width: 834px)':{
          width:'100%',
          height:'60px',
          backgroundColor:Color.black,
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          $nest:{
            '& .btnRes':{
              minWidth:'97px',
              width: '97px',
              padding:'5px 15px',
              fontSize:'10px'
            },
            '& .btnWhatsapp':{
              marginRight:'39px'
            }
          }
        }
      }
     
    },
    '@media screen and (max-width: 834px)':{
      height:'120px',
      flexDirection: 'column',
      justifyContent:'flex-end',
      borderBottom:'none',
      //display:'none'
    },
    '& .active':{
      fontWeight:'600',
    },
  }
})
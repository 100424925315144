import {style} from 'typestyle'
import {Color, Font} from '../var'

export const AboutStyle = style({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Avenir',
  $nest: {
    '& .infoData':{
      width: '100%',
      display: 'flex',
      paddingTop: '100px',
      paddingBottom: '100px',
      $nest: {
        '& .line': {
         marginTop: '10px',
         $nest:{
           '@media screen and (max-width: 700px)':{
             display:'none'
           }
         }
        },
       '& .text': {
         marginLeft:'16px',
         width: '50%',
         $nest: {
           '& .infoTitle': {
             fontSize: Font.medium,
             fontWeight: 'bold',
             color: Color.black,
             $nest: {
               '@media screen and (max-width: 700px)':{
                 fontSize: Font.principal
               }
             }
           },
           '& .infoDescription': {
             marginTop: '34px',
             fontSize: Font.principal,
             color: Color.mediumGray,
             $nest:{
               '@media screen and (max-width: 700px)':{
                 fontSize: Font.normal,
                 marginTop: '14px',
                 fontWeight:'100',
                 $nest:{
                   '& ul':{
                     paddingLeft:'20px'
                   }
                 }
               }
             }
           },
           '@media screen and (max-width: 700px)':{
            width: '100%',
            order:'2',
            marginLeft:'0px'
           }
         }
       },
       '& .icon':{
         width: '30%',
         display:'flex',
         justifyContent:'flex-end',
         alignItems: 'center',
         $nest:{
           '@media screen and (max-width: 700px)':{
             width:'100%',
             justifyContent:'flex-start',
             marginBottom:'40px',
             $nest:{
               '& img':{
                 width: '68px'
               }
             }
           }
         }
       },
       '@media screen and (max-width: 700px)':{
          flexDirection:'column',
          paddingTop: '60px',
          paddingBottom: '60px', 
       }

      }
    },
  }
})
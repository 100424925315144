const url = "https://api-fggt-app.herokuapp.com/api/v1/fggt"

export const createContact = (data)=> {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  const content = JSON.stringify(data);
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: content,
    redirect: 'follow' 
  };
  return fetch(`${url}/contact`, requestOptions)
  .then(response => response.json)

}

export const createContactCarrer = (data)=> {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  const content = JSON.stringify(data);
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: content,
    redirect: 'follow'
  };
  return fetch(`${url}/carrer`, requestOptions)
  .then(response => response.json)

}

export const createSupplier = (data)=> {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  const content = JSON.stringify(data);
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: content,
    redirect: 'follow' 
  };
  return fetch(`${url}/service`, requestOptions)
  .then(response => response.json)

}
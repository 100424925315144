import React from 'react'
import parse from 'html-react-parser'
import Banner from '../utils/banner'
import {LogisticStyle} from '../../style/logistic/style'
import {container} from '../../style/var'
//lang
import { useTranslation } from 'react-i18next'

const Logistic = () => {
  const { t } = useTranslation()
  return (
    <div className={LogisticStyle}>
       <Banner
        img ='./assets/Desktop/Logistic/es-fggt-logistic-img1.png'
        imgResponsive= './assets/Desktop/Logistic/mo-fggt-logistic-img1.png'
        title = 'logistic.title'
      />
      <div className={`${container} infoLogistic`}>
        <div className={`logistic`}>
          <div className="textLogistic">
            {parse(t('logistic.text'))}
          </div>
          <div className="iconoLogistic">
            <img src="./assets/Desktop/AboutUs/icons/internacional.png" alt=""/>
          </div>
        </div>
      </div>

      <div className={`${container}  transportCompany`}>
        <div className="description">
          {parse(t('logistic.transport'))}
        </div>
        <div className="logos">
          <a href="https://www.brinksglobal.com/Default.aspx" target="_blank" rel='noreferrer'>
            <img src="./assets/Desktop/Logistic/es-fggt-logistic-img2.png" alt="Brinks Logo"/>
          </a>
          <a href="https://www.ferrarigroup.net/" target="_blank" rel='noreferrer' >
            <img src="./assets/Desktop/Logistic/es-fggt-logistic-img3.png" alt="Brinks Logo" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Logistic

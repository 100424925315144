import React, { useContext } from 'react'
import parse from 'html-react-parser'
//Components
import Banner from '../utils/banner'
import CallToAction from '../utils/callToAction'
//Style
import {AboutStyle} from '../../style/about/style.js'
import {container,lineTitle} from '../../style/var'
//Context
import {AboutContext} from '../../context/about'
//lang
import { useTranslation } from 'react-i18next'

function About() {
  const { t } = useTranslation()
  const {
    about
  } = useContext(AboutContext)
  return (
    <div className= {AboutStyle}>
      <Banner
        img ='./assets/Desktop/AboutUs/es-fggt-about-us-img1.png'
        imgResponsive= './assets/Mobile/AboutUs/mo-fggt-about-us-img1.png'
        title = 'about.title'
      />
      <div className='about'>
        {about.map((item,i)=>
          <div className='data' style={{backgroundColor: `${item.background}`}}>
            <div className={`${container} infoData `} >
              <div className='line'>
                <svg height='8' width='122'>
                  <line x1='0' y1='0' x2='122' y2='0' style={{stroke:'#333333',strokeWidth:2}} />
                </svg>
              </div>
              <div className='text'>
                <div className='infoTitle'>{t(item.title)}</div>
                <div className='infoDescription'>
                  {parse(t(item.text))}
                </div>
              </div>
              <div className='icon'>
                <img src={item.icon} alt=''/>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`${container} ${lineTitle}`}>{t('about.supliers')}</div>
      <CallToAction/>
    </div>
  )
}

export default About

import React from 'react'
//Style
import {BannerStyle} from '../../../style/utils/banner/style'
import {container,lineTitle} from '../../../style/var'
//lang
import { useTranslation } from 'react-i18next'
const Banner = ({ title, img,imgResponsive}) => {
  const { t } = useTranslation()
  return (
    <div className= {BannerStyle}>
      <div className="desktop" style={{backgroundImage:`url(${img}`}}>
      </div>
      <div className="mobile" style= {{backgroundImage:`url(${imgResponsive}`}}>
      </div>
      {title &&
      <div className={lineTitle}>
        <div className={`${container} title`}>
         {t(title)}
        </div>
      </div>
      }
    </div>
  )
}

export default Banner




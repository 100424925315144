import React,{useContext} from 'react'
//Components
import Banner from '../utils/banner'
import CallToAction from '../utils/callToAction'
//Context
import {DiamondsContext} from '../../context/diamonds'
//Style
import List from '../utils/list'

const Diamonds = () => {
  const {
    diamonds
  }= useContext(DiamondsContext)
  return (
    <div>
      <Banner
        img ='./assets/Desktop/Diamonds/es-fggt-diamonds-img1.png'
        imgResponsive= './assets/Mobile/Diamonds/mo-fggt-diamonds-img1.png'
        title = 'diamonds.title'
      />
      <List
        data = {diamonds}
      />
      <CallToAction/>
    </div>
  )
}
export default Diamonds
export const en = {
  menu:{
    home:'Home',
    about: 'About Us',
    metals:'Precious Metals',
    diamonds: 'Diamonds',
    gemstones:'Precious Gemstones',
    jewerly:'Jewelry',
    compliance:'Compliance',
    contact:'Contact',
    logistic: 'Logistics',
    nanoMetals:  'Nano Metals'
  },
    home: {
    banner: 'DIAMONDS & GOLD SUPPLY CHAIN EXPERTS',
    company:{
      title: 'Our Company',
      whatDo:{
        title: 'What We Do Best.',
        text: 'FGGT is a Dubai-based precious metals and diamonds supplier and buyer with the main objective of maximizing value through various opportunities in local and international markets.<br/><br/>We implement the highest level of compliance required by the international standards of trade and uphold our integrity and excellence. At FGGT, we are committed to sourcing conflict-free precious metals, diamonds, and precious gemstones. We conduct the required due diligence process within the supply chain and ensure compliance with local and international policies and procedures for worry-free delivery and supply.<br/><br/>FGGT is committed to its expansion plans, market leadership position and business goals. We provide unparalleled customer service and the highest quality standards throughout all aspects of our operations.'
      },
      commitment:{
        title:'Our Commitment.',
        text:'FGGT is a people company – this means that we put our Suppliers, Partners, customers and our employees at the center of everything we do. Our culture celebrates diversity and inclusion, while uniting around a common mission and distinct privilege of helping our People. We provide unique standards and commitment across our trusted partners.<br/><br/>We strive to make sure that our professionals, personal and social development opportunities to unleash their full potential and have equal opportunity for advancement, at FGGT, we nurture an inclusive environment towards our Supply Chain Associates and out Employees.'
      },
      values:{
        title:'Values.',
        text:'People come first. We support and appreciate our clients and team members, embrace differences, celebrate uniqueness, encourage development and reward performance.<b>We transform our future with courage and vision by working as a team.</b><br/><br/>We listen and always seek the truth together, and tell it like it is, even when it’s difficult. Communication is always open and clear and we strive to simplify and drive collaboration with honesty and respect. We delight in gaining the trust of our suppliers and partners by developing long-lasting relationships and providing the best quality service and value.'
      }
    }
  },
  about:{
    title: 'ABOUT US',
    services:{
      title: 'Our Services.',
      text:'<ul><li>Evaluation.</li><li>Appraising.</li><li>Planning.</li><li>Cutting and polishing of diamonds and precious gemstones.</li> <li>High jewelry manufacturing</li></ul>'
    },
    global:{
      title: 'Global Presence.',
      text:'FGGT enjoys strong relationships with qualified suppliers and partners internationally. By working with our global partners, we’ve gained the know-how and experience to execute and perform flawlessly in the precious metals, diamonds, gemstones, and jewelry industries worldwide'
    },
    supliers:'FOR SUPPLIERS',
  },
  preciousMetals:{
    title:'Precious Metals',
    sourcing:{
      title: 'Sourcing & Trading.',
      text:'FGGT’s experience in precious metals trading and sourcing has provided us with expertise in applying local and international standards in compliance with supply chain policies and procedures.<br/><br/>Our international market trading services and secure practices make FGGT a unique leader in our field. We maintain long-term relationships with qualified licensed gold exporters and precious metals dealers, miners, and traders. These partners are located worldwide in order to source and procure precious metals directly from local suppliers.'
    },
    refining:{
      title:'Refining',
      text:'The refining process increases the purity of gold. There are many ways to refine gold, and each of them has different levels of effectiveness, time frames and cost. Even after the refining process has taken place, sometimes the purity of gold may still be affected by the initial purity of the specific precious metal. FGGT currently works with the most recognized, transparent and reliable refineries in the UAE, where they provide clear detailed analysis of our customers’ precious metals that go through the refining process for their peace of mind.',
    },
    doreBars: {
      title:'Doré Bars.',
      text:'With our extensive global network of sourcing subsidiaries, complemented by our trusted suppliers and partners, we source verified doré gold, platinum and silver bars straight from the mines. FGGT offers high quality, value, and consistency in product and service to our clients.'
    },
    selling:{
      title:'Selling Gold, Platinum, and Silver in Dubai.',
      text:'The process of selling gold always requires a partner you can trust.  FGGT ensures absolute transparency, privacy, and safety when we buy your precious metals. We purchase all items of gold, platinum, and silver. For example:<br/><ul> <li>Old or broken jewelry (ancestral jewelry, scrap gold, broken gold, gold and silver jewelry, etc.).</li> <li>Old rings, chains, earrings, and bracelets.</li><li>Diamond jewelry, antique jewelry, jewelry with gemstones, and resale jewelry.</li><li>Silverware and silver-plated items, including silver cutlery.</li><li>Dental gold.</li><li>Coins made of gold and silver (ducats, schillings, etc.).</li><li>Gold bars and silver bars from all manufacturers.</li><li>Luxury watches and exclusive or antique watches made of steel.</li><li>Gold watches and silver watches, including broken ones.</li><li>Diamonds.</li></ul>'
    }
  },
  diamonds:{
    title:'Diamonds',
    certified:{
      title:'Certified Loose Diamonds.',
      text:'FGGT uses state-of-the-art manufacturing practices and ensures compliance with the highest health and safety standards, while managing value creation from rough to polished diamonds. With the incorporation of cutting-edge technologies in our procedures along with highly experienced personnel, we transform each rough diamond into a brilliant piece. Our meticulous diamond planning, sorting, grading, cutting, polishing, and final certification are supported by the expertise of our verified partners.'
    },
    fancy:{
      title:'Fancy Diamonds.',
      text:'Diamonds in colors are the most exclusive variety of precious stones in the world thanks to their unique sparkle and shine. FGGT sources these exceptional diamonds providing added value for our clients.'
    },
    rough:{
      title:'Rough Diamonds.',
      text:'FGGT is a leader in sourcing sustainable, and compliant (Conflict Free, Kimberley Process) rough diamonds from quality mines, and suppliers from all the major diamond producing countries. In addition to our existing vast network of suppliers, new ones continue to join our partner network, which allows us to diversify our portfolio and enables them to support their goals.'
    },
    selling:{
      title:'Selling Diamonds in Dubai.',
      text:'Just like precious metals, diamonds can also be valuable assets. FGGT can assist with the monetization of your diamonds as an authentic and trustworthy buyer.'
    }
  },
  gemstones:{
    title:'Precious Gemstones',
    info:'FGGT is known for sourcing exceptional and authentic quality gemstones that shows natural brilliance and sparkle and are bound to enhance the desire of our Customers.<br/><br/>FGGT is well known for importing & exporting extraordinary Precious Gemstones rough or polished across the globe. We take pride in being one of the leaders in the complete management of mineral value chain with an extensive hands-on, and on ground experience, extracting the rare treasures from mines in Latin America, South Asia, and Africa.',
    emeralds:{
      title:'Emeralds.',
      text:'FGGT has extensive expertise in importing and exporting rough and polished emeralds, the most desired gemstone in the world. We are your dedicated supply chain partner who will guarantee the best quality emeralds.'
    },
    selling:{
      title:'Selling Precious Gemstones in Dubai.',
      text:'FGGT is well known for importing and exporting extraordinary precious gemstones, rough or polished, across the globe. We take pride in being one of the leaders in the complete management of mineral value chain with an extensive hands-on, and on-ground experience, extracting the rare treasures from mines in Latin America, South Asia and Africa.'
    }
  },
  compliance:{
    title: 'Compliance',
    diligence: {
      title: 'Due Diligence.',
      text:'FGGT demonstrates enforcement of/and compliance to Local and International Supply Chain Security Standards where, buyers of Precious Metals, Jewelry, Diamonds & Gemstones must continuously assess their Supply Chain to identify, mitigate, and eliminate potential security risks, and monitor product Logistics.'
    },
    policies:{
      title:'Policies & Procedures.',
      text:'FGGT ensures the adherence to the stringent compliance standards and policies that must meet and be applied by suppliers, external investors, clients, and associates for engaging in any of the business activities with the company which are as follows: <br/><br/><div className="list"><ul><li>SUPPLY CHAIN DUE DILIGENCE POLICY FGGT 2021. <a href="./assets/documents/WEB SUPPLY CHAIN DUE DILIGENCE POLICY FGGT Template .pdf" target="_blank">See documentation here.</a></li> <li>AML MANUAL FOR POLICIES & PROCEDURES FGGT 2021. <a href="./assets/documents/WEB AML MANUAL FOR POLICIES & PROCEDURES FGGT Template .pdf" target="_blank">See documentation here</a></li> <li>AML POLICY COMPLIANCE & SUPERVISORY PROCEDURES FGGT 2021. <a href="./assets/documents/WEB AML POLICY COMPLIANCE & SUPERVISORY PROCEDURES FGGT Template .pdf" target="_blank">See documentation here</a></li><li>CONFLICT FREE GOLD STANDARD PRECIOUS METALS SOURCING  FGGT 2021. <a href="./assets/documents/WEB CONFLICT FREE GOLD STANDARD PRECIOUS METALS SOURCING  FGGT Template .pdf" target="_blank">See documentation here</a></li></ul></div>'
    },
    international:{
      title:'International Precious Metals - Diamonds - Precious Gemstones Policies and Procedures for Trading and Sourcing.',
      text:'FGGT not only adheres its own standards and policies in place; but also adheres to, and ensures that all business is carried out as per the standards and policies set by the international and global governing bodies which are as follows:'
    }

  },
  jewerly:{
    title:'Jewelry',
    design:{
      'title': 'Enter the world of fashion and design with your own jewelry collection with the help of FGGT.',
      'text':'Jewelry can be made of a wide range of materials. Diamonds and gemstones as well as amber, coral, precious metals, beads and shells have been widely used throughout history. In most cultures, jewelry is perceived as a status symbol for its material properties, its patterns, or for its meaningful symbols. Jewelry has been created to embellish specific body parts such as hairpins, navel rings, toe rings and more.'
    },
    hight:{
      title:'High Jewelry Design and Creation.',
      text:'FGGT’s passion has inspired its high jewelry creations. We share our unique vision of the craftmanship, marked by captivating sources of creativity and virtuous know-how. Each meticulously selected stone is chosen for its exceptional quality, resonance and the emotion it carries within the specific piece of jewelry. <br/><br/><span>At FGGT we can create the jewelry piece of your dreams,completely unique and one of a kind.</span>'
    }
  },
  contact: {
    title:'Contact Us',
    form: {
      name:'Name',
      surname:'Surname',
      email:'E-mail',
      phone: 'Phone number',
      cancel:'Cancel',
      send:'Send',
      carrers:'Carrers'
    }
  },
  footer: {
    call: 'CALL US',
    write:'WRITE US',
    visit: 'VISIT US',
    business: 'BUSINESS HOURS',
    open:'Sunday - Thursday',
    closeDays: 'Friday and Saturday',
    close:'Closed',
    navigation:'NAVIGATION'

  },
  callToAction: {
    title: 'Become an FGGT Supplier.',
    metals: 'Precious Metals supplier',
    diamonds: 'Diamonds & Precious Gemstones.',
    newClient:'Register as a Client.',
    button:'Apply now'
  },
  logistic: {
    title: 'Logistics',
    text: 'At FGGT, we are committed to maintaining unsurpassed shipping and delivery standards. Whether it is daily, weekly, monthly or on-demand precious metals, diamonds and gemstones shipments, we can deliver around-the-clock. This is possible thanks to our strategic partnerships with leading logistics providers and accounts with major secured transport companies, such as Brinks, Ferrari and others. All of FGGT’s logistics operations are conducted in accordance with international requirements and policies, and we strictly adhere to them.<br/><br/>FGGT partnerships aid us in expediting all shipments which increases the efficiency of our import-export processes while complying with local and international authorities.<br/><br/>FGGT is known for sourcing quality precious metals and authentic diamonds and gemstones that show natural brilliance, sparkle and are appealing to our clients.<br/><br/>With the assistance of our affiliate, <a href="https://fronterasky.com/" target="_blank">FronteraSky</a>, we will quickly and privately organize air and ground transportation of precious metals, diamonds, gemstones, jewelry, and other valuable cargo. Unique items can be transported in special sealed containers that maintain a certain constant temperature and humidity. At the same time, we will not only ensure compliance with all air transport processes, but also provide security services to guarantee precautionary measures on your request. FGGT will carefully monitor compliance with your requirements for safety, quality and confidentiality of transport.',
    transport: ' We are working hand to hand with the most reputable <br/>security transport companies.'
  },
  nanoMetals: {
    title: 'Nano Metals',
    nannoCopper: {
      title: 'Nano Copper.',
      text: 'New Frontiers Gold & Gems marks new era of nano technology by partnering with Minera Monte Alto, premier supplier of 100% organic nano copper.'
    },
    process: {
      title: 'Process for Obtaining Nano Particles from Organic Copper',
      text: '<ol><li><b>Obtaining Copper Mineral.</b><br/>At the mining properties of Compañia Minera Monte Alto, located in the Fourth Region of Coquimbo in Chile, we obtain copper ore in the state of Oxides and Sulfides through underground exploitation. The ore is then transported from our mines to our processing plant.</li><br/> <li><b>Copper Ore Processing.</b><br/>The mineral is received in our processing plant and classified according to its purity, using only the highest-quality mineral for the process of obtaining Nano Copper. The waste ore is processed to obtain copper-gold and polymetallic concentrates. The classified material is crushed and sorted to a-size of 6mm.</li><br/><li><b>Bio-Leaching Processes.</b><br/>The mineral is deposited in our leaching pools. Then, the pools are loaded with a solution containing bacteria that, through an organc process and continuous flow of 10 days, leaves the solution loaded with copper. The solution is then discharged, passing through several filters, channeled and deposited in the electrowinning cells.</li><br/><li><b>Electro-Generation Process.</b><br/>The cothodes are then slowly charged with copper nanoparticles through longitudinal energy. Once the cathodes are ionically charged, the reverse discharge process takes place and then we take them to our quality control and classification section.</li><br/><li><b>Quality Control and Packaging.</b><br>One the nanoparticles pass through quality control, microscopy and nanometric classification, they proceed to the packaging, sealing and labeling section.<br/><br/>Finally, we pack the nanoparticlesin customized security cases which are sealed, transported and transferred custody to reputable security transportation companies, such as "PROSEGUR", pending the coordination and details of the sale.</li></ol>',
      titleImage1: 'Copper Mineral',
      titleImage2: 'Bio-Leaching',
      titleImage3: 'Electro-Generation',
      titleImage4: 'Quality Control / Packaging'
    },
    table:[
      {
        physical: 'Regular Nano Cu spherical particles with sizes from 20nm to 100nm (Other formats upon request).',
        chemical: 'Stable at room temperature in closed containers under normal storage and handling conditions.',
        visual: 'Powder, red to brown in color.',
        practical:'Advanced medications and supplements, surgical instruments and posthesis.'
      },
      {
        physical: 'Metallic Cu.',
        chemical: 'Long shelf life, more than 24 months.',
        visual: 'Dispersion in oily organic media: terpineol, ethylene glycol, Vaseline, ethanol, methanol and others according to customer needs.',
        practical:'Heat sinks in materias or thermal conductors.'
      },
      {
        physical: 'Non-oxidative.',
        chemical: 'Non-radioactive.',
        visual: '500 mg sealed strainless-steel container.',
        practical:'Electric Superconductors, construction materials.'
      },
      {
        physical: 'Environmentally friendly production.',
        chemical: 'Highly refined without contaminating traces.',
        visual: '',
        practical:'Welding material for metals and high-resistance alloys.',
      },
      {
        physical: 'Cu of "HG" specifications (cathode grade A) with purity greater than 99.98%.',
        chemical: '',
        visual: '',
        practical:'Shielding against electromagnetic interference (EMI).'
      },
      {
        physical: '100% organic.',
        chemical: '',
        visual: '',
        practical:'Effective catalyst for chemical reactions and for the synthesis of methanol and glycol.'
      },
      {
        physical: 'Purity: 99,985 %',
        chemical: '',
        visual: '',
        practical:''
      }
    ],
    tableDisclaimer: '*Includes wellbeing, medical, cosmetics, naval, pharmaceutical, construction, chemical, energy, electronics and defense',
    titleLaboratories:'OUR PRODUCT HAS BEEN CERTIFIED BY PRESTIGIOUS INTERNATIONAL LABORATORIES:'
  }

}
import {style} from 'typestyle'

export const GaleryStyle = style({
  width: '100%',
  $nest: {
    '& .galery': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      width:'100%',
      gap:'2px',
      rowGap: '1px',
      $nest: {
        '& .item': {
           width:'100%',
           //marginRight: '2px',
           $nest: {
             '& img':{
               width: '100%'
             },
             '& .desktop': {
               display: 'flex',
               $nest: {
                 '@media screen and (max-width: 700px)':{
                  display: 'none',
                 }
               }
             },
             '& .mobile': {
               display: 'none',
               $nest: {
                 '@media screen and (max-width: 700px)':{
                   display: 'flex'
                 }
               }
             }
           }
        },
        '@media screen and (max-width: 700px)':{
          gridTemplateColumns: 'repeat(2,1fr)',
          gap:'1px',
          rowGap: '1px',
          $nest: {
            '& :nth-child(3)':{
              gridColumn: '1/3'
            }
          }
        }
      }
    },
  }
})
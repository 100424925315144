import React, {createContext, useState}  from 'react'
const PreciousMetalsContext = createContext()
const { Provider, Consumer } = PreciousMetalsContext

const PreciousMetalsProvider = ({children}) =>  {
    const [ list, setList] = useState([
      {
        title: 'preciousMetals.sourcing.title',
        text : 'preciousMetals.sourcing.text',
        image1: './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img2.png',
        image1mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img2.png',
        width: '62%',
        image2:'./assets/Desktop/PreciousMetals/es-fggt-precious-metals-img3.png',
        image2mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img3.png',
        width2: '31%'
      },
      {
        title: 'preciousMetals.refining.title',
        text: 'preciousMetals.refining.text',
        image1: './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img4.png',
        image1mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img4.png',
        width: '31%',
        image2:'./assets/Desktop/PreciousMetals/es-fggt-precious-metals-img5.png',
        image2mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img5.png',
        width2: '62%'
      },
      {
        title: 'preciousMetals.doreBars.title',
        text: 'preciousMetals.doreBars.text',
        image1: './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img6.png',
        image1mobile: './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img6.png',
        width: '62%',
        image2:'./assets/Desktop/PreciousMetals/es-fggt-precious-metals-img7.png',
        image2mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img7.png',
        width2: '31%'
      },
      {
        title: 'preciousMetals.selling.title',
        text: 'preciousMetals.selling.text',
        image1: './assets/Desktop/PreciousMetals/es-fggt-precious-metals-img8.png',
        image1mobile: './assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img8.png',
        width: '31%',
        image2:'./assets/Desktop/PreciousMetals/es-fggt-precious-metals-img9.png',
        image2mobile:'./assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img9.png',
        width2: '62%'
      }
    ])
    return(
        <Provider
            value={{
              list, setList
            }}
        >
           {children} 
        </Provider>
    )
}

export { PreciousMetalsProvider, Consumer as PreciousMetalsConsumer, PreciousMetalsContext }
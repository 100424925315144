import React, { useContext } from "react";
//API
import { createContact, createContactCarrer } from "../../api/contact";
//components
import Banner from "../utils/banner";
import Galery from "../utils/galery";
import { validateGlobal, toastMessage } from "../utils/validateGlobal";
//Context
import { ContactContext } from "../../context/contact";
//Style
import { ContactStyle } from "../../style/contact/style";
import { button, lineTitle, container } from "../../style/var";
//Lang
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const {
    galery,
    name,
    setName,
    surname,
    setSurname,
    email,
    setEmail,
    phone,
    setPhone,
    comment,
    setComment,
    nameCarrer,
    setNameCarrer,
    surnameCarrer,
    setSurnameCarrer,
    emailCarrer,
    setEmailCarrer,
    phoneCarrer,
    setPhoneCarrer,
    commentCarrer,
    setCommentCarrer,
  } = useContext(ContactContext);

  const cancel = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    setComment("");
  };
  const carrerCancel = () => {
    setNameCarrer("");
    setSurnameCarrer("");
    setPhoneCarrer("");
    setEmailCarrer("");
    setCommentCarrer("");
  };
  const sendInfo = () => {
    const data = { name, surname, email, phone, comment };
    const [validate] = validateGlobal(
      {
        name: "Require|String",
        surname: "String",
        email: "Require|String",
        phone: "String",
        comment: "String",
      },
      data,
      {
        message: {
          name: "El nombre es requerido",
          email: "El email no es valido",
          ErrorDefault: "Uy algo salio mal...",
          CorrectValidate: "",
        },
        position: "bottom-right",
        correctValidate: false,
      }
    );
    if (validate) {
      createContact(data)
        .then(() => {
          toastMessage({
            message:
              "Muy pronto estaremos en contacto, Gracias por contar con nosotros",
            position: "bottom-right",
          });
          setName("");
          setSurname("");
          setEmail("");
          setPhone("");
          setComment("");
        })
        .catch(() =>
          toastMessage({
            ErrorDefault: "Ups..something went wrong...",
            position: "bottom-right",
            error: true,
          })
        );
    }
  };

  const sendCarrerInfo = () => {
    const name = nameCarrer;
    const surname = surnameCarrer;
    const email = emailCarrer;
    const phone = phoneCarrer;
    const comment = commentCarrer;
    const data = { name, surname, email, phone, comment };
    const [validate] = validateGlobal(
      {
        name: "Require|String",
        surname: "String",
        email: "Require|String",
        phone: "String",
        comment: "String",
      },
      data,
      {
        message: {
          name: "Name is Require",
          email: "The email is not valid",
          ErrorDefault: "Ups..something went wrong...",
          CorrectValidate: "",
        },
        position: "bottom-right",
        correctValidate: false,
      }
    );
    if (validate) {
      createContactCarrer(data)
        .then(() => {
          toastMessage({
            message: "We will be in touch very soon, Thank you for having us",
            position: "bottom-right",
          });
          setNameCarrer("");
          setSurnameCarrer("");
          setPhoneCarrer("");
          setEmailCarrer("");
          setCommentCarrer("");
        })
        .catch(() =>
          toastMessage({
            message: "Uy algo salio mal...",
            position: "bottom-right",
            error: true,
          })
        );
    }
  };
  return (
    <div className={ContactStyle}>
      <Banner
        img="./assets/Desktop/Contact/es-fggt-contact-img1.png"
        imgResponsive="./assets/Mobile/Contact/mo-fggt-contact-img1.png"
        title="contact.title"
      />
      <div className="form">
        <div className="border">
          <div className="content">
            <div className="item">
              <label>{t("contact.form.name")}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="item">
              <label>{t("contact.form.surname")}</label>
              <input
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="item">
              <label>{t("contact.form.email")}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="item">
              <label>{t("contact.form.phone")}</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="item comment">
              <label>Comentario</label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="buttons">
              <div className={`${button} cancel`} onClick={() => cancel()}>
                {t("contact.form.cancel")}
              </div>
              <div className={`${button} send`} onClick={() => sendInfo()}>
                {t("contact.form.send")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${container} ${lineTitle}`}>
        {t("contact.form.carrers")}
      </div>
      <div className="form">
        <div className="border">
          <div className="content">
            <div className="item">
              <label>{t("contact.form.name")}</label>
              <input
                type="text"
                value={nameCarrer}
                onChange={(e) => setNameCarrer(e.target.value)}
              />
            </div>
            <div className="item">
              <label>{t("contact.form.surname")}</label>
              <input
                type="text"
                value={surnameCarrer}
                onChange={(e) => setSurnameCarrer(e.target.value)}
              />
            </div>
            <div className="item">
              <label>{t("contact.form.email")}</label>
              <input
                type="text"
                value={emailCarrer}
                onChange={(e) => setEmailCarrer(e.target.value)}
              />
            </div>
            <div className="item">
              <label>{t("contact.form.phone")}</label>
              <input
                type="text"
                value={phoneCarrer}
                onChange={(e) => setPhoneCarrer(e.target.value)}
              />
            </div>
            <div className="item comment">
              <label>Comentario</label>
              <textarea
                value={commentCarrer}
                onChange={(e) => setCommentCarrer(e.target.value)}
              />
            </div>
            <div className="buttons">
              <div
                className={`${button} cancel`}
                onClick={() => carrerCancel()}
              >
                {t("contact.form.cancel")}
              </div>
              <div
                className={`${button} send`}
                onClick={() => sendCarrerInfo()}
              >
                {t("contact.form.send")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Galery data={galery} />
    </div>
  );
};
export default Contact;

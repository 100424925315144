
import React from 'react'
import Router from './router'

//components
import Menu from './Navbar'
import Footer from './footer'

const Aplication = () => {
  return (
    <div>
      <Menu/>
        <Router/>
      <Footer/>
    </div>
  )
}

export default Aplication
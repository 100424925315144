import React, {createContext, useState}  from 'react'
const ContactContext = createContext()
const { Provider, Consumer } = ContactContext


const ContactProvider = ({children}) =>  {
    const[ galery, setGalery ] = useState([
      {img: './assets/Desktop/Contact/es-fggt-contact-img2.png',imgResponsive: './assets/Desktop/Contact/es-fggt-contact-img2.png'},
      {img: './assets/Desktop/Contact/es-fggt-contact-img3.png',imgResponsive: './assets/Desktop/Contact/es-fggt-contact-img3.png'},
      {img: './assets/Desktop/Contact/es-fggt-contact-img4.png', imgResponsive: './assets/Mobile/Contact/mo-fggt-contact-img4.png'}
    ])
    const[name,setName] = useState('')
    const[surname,setSurname] = useState('')
    const[email,setEmail] = useState('')
    const[phone,setPhone] = useState('')
    const [comment,setComment] = useState('')

    const[nameCarrer,setNameCarrer] = useState('')
    const[surnameCarrer,setSurnameCarrer] = useState('')
    const[emailCarrer,setEmailCarrer] = useState('')
    const[phoneCarrer,setPhoneCarrer] = useState('')
    const [commentCarrer,setCommentCarrer] = useState('')

    return(
      <Provider
          value={{
            galery, setGalery,
            name,setName,
            surname,setSurname,
            email,setEmail,
            phone,setPhone,
            nameCarrer,setNameCarrer,
            surnameCarrer,setSurnameCarrer,
            emailCarrer,setEmailCarrer,
            phoneCarrer,setPhoneCarrer,
            comment,setComment,
            commentCarrer,setCommentCarrer
          }}
        >
        {children}
      </Provider>
    )
}

export { ContactProvider, Consumer as ContactConsumer, ContactContext }
import { style } from 'typestyle'

/* Colors */

export const Color = {
  white: '#ffffff',
  black: '#333333',
  lightGray: '#b9b6b6',
  mediumGray: '#666666',
  darkGray: '#5a5a5a',
  grey: '#2c3e45',
  darkGold: '#635836',
  mediumGold: '#725f24',
  lightGold: '#c6af6b',
  lightOrange: '#f2f0f0',
  orange: '#cba849'
}
export const Font = {
  MegaBig: '50px',
  Big: '40px',
  minBig:'35px',
  medium: '30px',
  intermedium: '25px',
  titleMenu:'23px',
  principal: '20px',
  normal: '16px',
  minimal: '15px',
  micro: '14px',
  min: '12px',
  mini:'11px'
}
export const button = style({
  minWidth: '260px',
  padding: '10px 0',
  textAlign: 'center',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  appearance: 'none',
  borderRadius: '50px',
  border: '2px solid ',
  borderColor: Color.lightGold,
  fontFamily: 'Avenir',
  fontWeight: 'bold',
  color: Color.lightGold,
  cursor: 'pointer',
  $nest: {
    '@media screen and (max-width: 1024px)': {
      minWidth: '200px',
      fontSize: Font.principal
    },
    '@media screen and (max-width: 834px)': {
      minWidth: '150px',
      fontSize: Font.Mini
    },
  }
})

export const container = style({
  width: '100%',
  padding: '0 140px',
  boxSizing: 'border-box',
  $nest: {
    '@media screen and (max-width: 1440px)': {
      padding: '0 100px',
    },
    '@media screen and (max-width: 1024px)': {
      padding: '0 50px',
    },
    '@media screen and (max-width: 834px)':{
      padding: '0 20px'
    },
    '@media screen and (max-width: 600px)':{
      padding: '0 37px'
    }
  }
})

export const lineTitle = style({
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, #c6af6b 51%, #635836)',
  fontSize: Font.MegaBig,
  color: Color.white,
  textTransform: 'uppercase',
  $nest: {
    '@media screen and (max-width: 700px)':{
      fontSize:Font.medium,
      height:'75px',
      
    }
  }
})
import React, {createContext, useState}  from 'react'
const ComplianceContext = createContext()
const { Provider, Consumer } = ComplianceContext

const ComplianceProvider = ({children}) =>  {
    const [ list, setList] = useState([
      {
        title: 'compliance.diligence.title',
        text : 'compliance.diligence.text'
      },
      {
        title: 'compliance.policies.title',
        text: 'compliance.policies.text'
      },
      {
        title: 'compliance.international.title',
        text: 'compliance.international.text'
      }]
    )
    const [icon,setIcon]= useState([
      {
        image:'./assets/Desktop/Compliance/icons/imagen-1.png',
        width: '50%',
        link:'http://www.lbma.org.uk/responsible-sourcing',
        textLink:'Visit lbma.org.uk'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-2.png',
        width: '50%',
        link:'https://www.oecd.org/daf/inv/mne/mining.html',
        textLink:'Visit oecd.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-3.png',
        link:'https://www.fatf-gafi.org/documents/documents/fatfguidanceontherisk-basedapproachfordealersinpreciousmetalsandstones.html',
        width: '50%',
        textLink:'Visit fatf-gafi.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-4.png',
        link:'https://www.imf.org/external/np/leg/amlcft/eng/aml4.htm#antimoney',
        width: '50%',
        textLink:'Visit imf.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-5.png',
        link:'https://www.dmcc.ae/gateway-to-trade/commodities/gold/responsible-sourcing',
        width: '50%',
        textLink:'Visit dmcc.ae'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-6.png',
        link:'https://www.kimberleyprocess.com/en/what-kp',
        width: '50%',
        textLink:'Visit kimberleyprocess.com'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-7.png',
        link:'http://www.responsiblemineralsinitiative.org/media/docs/CFSI_DD_ReasonablePracticesforDownstreamCompanies_Aug2013.pdf',
        width: '50%',
        textLink:'Visit responsiblemineralsinitiative.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-8.png',
        link:'https://www.gold.org/about-gold/gold-supply/responsible-gold/conflict-free-gold-standard',
        width: '50%',
        textLink:'Visit gold.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-9.png',
        link:'http://www.responsiblemineralsinitiative.org/minerals-due-diligence/gold/',
        width: '50%',
        textLink:'Visit responsiblemineralsinitiative.org'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-10.png',
        link:'https://www.worlddiamondcouncil.org/',
        width: '50%',
        textLink:'Visit worlddiamondcouncil'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-11.png',
        link:'https://www.responsiblejewellery.com/standards/',
        width: '80%',
        textLink:'Visit responsiblejewellery.com'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-12.png',
        link:'https://www.thomsonreuters.com/en/press-releases/2014/thomson-reuters-launches-accelus-world-check-one.html',
        width: '80%',
        textLink:'Visit thomsonreuters.com'
      },
      {
        image:'./assets/Desktop/Compliance/icons/imagen-13.png',
        link:'http://www.diamondfacts.org/',
        width: '80%',
        textLink:'Visit diamondfacts.org'
      },

      {
        image:'./assets/Desktop/Compliance/icons/es-fggt-compliance-img17.png',
        link:'https://www.economy.gov.ae',
        width: '80%',
        textLink:'Visit www.economy.gov.ae'
      },
      {
        image:'./assets/Desktop/Compliance/icons/es-fggt-compliance-img16.png',
        link:'https://www.uaefiu.gov.ae/en/',
        width: '80%',
        textLink:'Visit www.uaefiu.gov.ae'
      },
      {
        image:'./assets/Desktop/Compliance/icons/es-fggt-compliance-img15.png',
        link:'https://unite.un.org/goaml/',
        width: '80%',
        textLink:'Visit unite.un.org'
      }
    ])
    return(
        <Provider
            value={{
              list, setList,
              icon,setIcon
            }}
        >
           {children} 
        </Provider>
    )
}

export { ComplianceProvider, Consumer as ComplianceConsumer, ComplianceContext }
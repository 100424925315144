import "./App.css";
import Aplication from "./components";
import { HomeProvider } from "./context/home";
import { DiamondsProvider } from "./context/diamonds";
import { PreciousMetalsProvider } from "./context/preciousMetals";
import { PreciousGemstonesProvider } from "./context/preciousGemstones";
import { CallToActionProvider } from "./context/callToAction";
import { ComplianceProvider } from "./context/compliance";
import { ContactProvider } from "./context/contact";
import { AboutProvider } from "./context/about";
import { MenuProvider } from "./context/menu";
import { FormProvider } from "./context/form";
import { JewelryProvider } from "./context/jewelry";
import { NanoMetalsProvider } from "./context/nanoMetals";
//Router
import { BrowserRouter as Router } from "react-router-dom";
//Lang
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./lang";

const App = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: ".",
    interpolaion: {
      escapaValue: false,
    },
  });
  return (
    <>
      <HomeProvider>
        <AboutProvider>
          <DiamondsProvider>
            <PreciousMetalsProvider>
              <PreciousGemstonesProvider>
                <CallToActionProvider>
                  <ComplianceProvider>
                    <ContactProvider>
                      <MenuProvider>
                        <JewelryProvider>
                          <NanoMetalsProvider>
                            <FormProvider>
                              <Router>
                                <Aplication />
                              </Router>
                            </FormProvider>
                          </NanoMetalsProvider>
                        </JewelryProvider>
                      </MenuProvider>
                    </ContactProvider>
                  </ComplianceProvider>
                </CallToActionProvider>
              </PreciousGemstonesProvider>
            </PreciousMetalsProvider>
          </DiamondsProvider>
        </AboutProvider>
      </HomeProvider>
    </>
  );
};

export default App;

import React, {createContext, useState}  from 'react'
const AboutContext = createContext()
const { Provider, Consumer } = AboutContext
const AboutProvider = ({children}) =>  {
    const [ about, setAbout] = useState([
      {
        title: 'about.services.title',
        text : 'about.services.text',
        icon: './assets/Desktop/AboutUs/icons/018-clipboard-1.png',
        background: 'ffffff'
      },
      {
        title: 'about.global.title',
        text: 'about.global.text',
        icon:'./assets/Desktop/AboutUs/icons/055-international-1.png',
        background: '#f2f0f0'
      }
    ])
    return(
      <Provider
        value={{
          about, setAbout
        }}
      >
        {children} 
      </Provider>
    )
}

export { AboutProvider, Consumer as AboutConsumer, AboutContext }
import React, {createContext, useState}  from 'react'
const CallToActionContext = createContext()
const { Provider, Consumer } = CallToActionContext


const CallToActionProvider = ({children}) =>  {
    const [ card, setCard] = useState([
      {
        icon: './assets/icons/oro.svg',
        title : 'callToAction.metals',
        link: '/formSupplier',
        banner :{
          img :'./assets/Desktop/PreciousMetals/es-fggt-precious-metals-img1.png',
          imgResponsive: './assets/Mobile/PreciousMetals/mo-fggt-precious-metals-img1.png',
          title : 'callToAction.metals',
          icon: './assets/Desktop/PreciousMetals/icono.png',
        }

      },
      {
        icon: './assets/icons/diamante.svg',
        title : 'callToAction.diamonds',
        link: '/formSupplier',
        banner :{
          img :'./assets/Desktop/Diamonds/es-fggt-diamonds-img1.png',
          imgResponsive: './assets/Mobile/Diamonds/mo-fggt-diamonds-img1.png',
          title : 'callToAction.diamonds',
          icon: './assets/Desktop/Diamonds/icon.png',
        }
      },
      {
        icon: './assets/icons/persona.svg',
        title : 'callToAction.newClient',
        link: '/formSupplier',
        banner :{
          img :'./assets/Desktop/Contact/es-fggt-contact-img1.png',
          imgResponsive: './assets/Mobile/Contact/mo-fggt-contact-img1.png',
          title : 'callToAction.newClient',
          icon: './assets/Desktop/Contact/icon.png',
        }
      },
    ])
    return(
        <Provider
            value={{
              card, setCard
            }}
        >
           {children} 
        </Provider>
    )
}

export { CallToActionProvider, Consumer as CallToActionConsumer, CallToActionContext }
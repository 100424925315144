import {style} from 'typestyle'
import {Color, Font} from '../var'

export const JewerlyStyle = style({
  width: '100%',
  fontFamily:'Avenir',
  $nest: {
    '& .jewerly': {
      display: 'flex',
      flexDirection: 'column',
      margin: '100px 0',
      $nest: {
        '& .titleJewerly':{
           color: Color.lightGold,
           fontSize:Font.MegaBig,
           textTransform: 'uppercase'
        },
        '& .info':{
          width: '100%',
          display: 'flex',
          marginTop: '29px',
          marginBottom: '20px',
          $nest: {
            '& .line': {
             marginTop: '10px'
            },
           '& .text': {
             marginLeft:'16px',
             width: '70%',
             $nest: {
               '& .infoTitle': {
                 fontSize: Font.medium,
                 fontWeight: 'bold',
                 color: Color.black,
                 $nest: {
                   '@media screen and (max-width: 700px)':{
                     fontSize: Font.principal
                   }
                 }
               },
               '& .infoDescription': {
                 marginTop: '34px',
                 fontSize: Font.principal,
                 color: Color.mediumGray,
                 $nest:{
                  '& span':{
                    color: Color.lightGold,
                    fontSize: Font.minBig,
                    $nest:{
                      '@media screen and (max-width: 1024px)':{
                        '& br':{
                          display:'none'
                        }
                      },
                      '@media screen and (max-width: 600px)':{
                        fontSize: Font.intermedium
                      }
                    }
                  },
                  '@media screen and (max-width: 700px)':{
                    fontSize: Font.normal
                  }
                 }
               },
               '@media screen and (max-width: 700px)':{
                 marginLeft: '0px',
                 width: '100%',
               }
             }
           }
          }
        },
      }
    },
    '@media screen and (max-width: 700px)':{
      $nest: {
        '& .jewerly': {
          margin: '60px 0',
          $nest: {
            '& .titleJewerly':{
              fontSize:Font.medium,
            }
          }
        },
        '& .info':{
          marginTop: '23px',
          $nest: {
            '& .line':{
              display:'none'
            },
          }
        }
      }
    }
  }
})
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Link } from 'react-router-dom'
//Style
import { FooterStyle } from '../../style/footer/style'
import { container } from '../../style/var'
import {useTranslation} from 'react-i18next'
const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className={FooterStyle}>
      <div className={`${container} content`}>
        <div className="item logo">
          <img src="./assets/LogoFooter.svg" alt="Logo Frontiers" className="desktop" />
          <img src="./assets/LogoResponsiveFooter.svg" alt="Logo Frontiers" className="mobile" />
        </div>
        <div className="item">
          <div><span className="title">{t('footer.call')}</span> <br /><a href="tel:+971523345409">971 52 334 5409</a></div>
          <div><span className="title">{t('footer.write')}</span> <br /><a href="mailto:info@frontiersggtrading.com">info@frontiersggtrading.com</a></div>
          <div><span className="title">{t('footer.visit')}</span><br />Media One Tower, Office 3208<br />Media City Dubai, United Arab Emirates<a href="tel:+971044253385">+971 (0) 4 - 425 - 3385</a></div>
        </div>
        <div className="item">
          <div><span className="title">{t('footer.business')}</span> <br />{t('footer.open')}<br />09:00 a.m. - 05:00 p.m.</div>
          <div>{t('footer.closeDays')}<br />{t('footer.close')}</div>
        </div>
        <div className="item">
          <div><span className="title">{t('footer.navigation')}</span>
            <Link to='/' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.home')}</Link>
            <Link to='/about' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.about')}</Link>
            <Link to='/PreciousMetals' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.metals')}</Link>
            <Link to='/nanoMetals' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.nanoMetals')}</Link>
            <Link to='/diamonds' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.diamonds')}</Link>
            <Link to='/preciousGemstones' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.gemstones')}</Link>
            <Link to='/compliance' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.compliance')}</Link>
            <Link to='/contact' onClick={()=>window.scrollTo(0, 0,)}>{t('menu.contact')}</Link>
          </div>
        </div>
      </div>
      <div className={`${container} allCopy`}>
        <div className='copyContent'>
            <div className='copy'>COPYRIGHT ©2021 FRONTIERS GOLD & GEMS TRADING ALL RIGHTS RESERVED</div>
        </div>
        <div className='copyContent'>
            <a className='decorationNone' href="https://alunideas.com/" target="_blank">Made with love by alunideas.com</a>
          </div>
      </div>
    </div>
  )
}
export default Footer
import {style} from 'typestyle'

import {Color, Font} from '../var'

export const PreciousGemstonesStyle= style({
  width: '100%',
  fontFamily: 'Avenir',
  $nest: {
    '& .infoGemstones':{
      backgroundColor: Color.lightOrange,
      $nest:{
        '& .textGemstones': {
          paddingTop:'100px',
          paddingBottom: '100px',
          fontSize:Font.intermedium,
          width: '83%',
          $nest: {
            '@media screen and (max-width: 700px)':{
              fontSize:Font.normal,
              width: '100%',
              paddingTop:'60px',
              paddingBottom: '60px',
            }
          }
        }
      }
    }
  }
})
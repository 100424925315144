export const fr = {
  menu:{
    home:'Accueil',
    about: 'À Propos de Nous',
    metals:'Métaux Précieux',
    diamonds: 'Diamants',
    gemstones:'Pierres Précieuses',
    jewerly:'Bijoux',
    compliance:'Conformité',
    contact:'Nous Contacter',
    logistic: 'Logistique',
    nanoMetals: ''
  },
    home: {
    banner: 'Métaux précieux, bijoux, diamants et pierres précieuses avec <br/>les plus hauts niveaux de réglementation internationale.',
    company:{
      title: 'Our Company',
      whatDo:{
        title: 'Notre Compagnie',
        text: "Ce que nous faisons de mieux. FGGT est une société basée à Dubaï, l’objectif de la société est de maximiser la valeur pour les actionnaires par la découverte et le développement d’opportunités dans la base de métaux précieux, de bijoux, de diamants et de pierres précieuses sur le marché local et international.<br/><br/>Nous utilisons les plus hauts niveaux de réglementation selon les normes internationales du commerce, en respectant les normes les plus élevées d'intégrité, d'excellence et de conformité. Chez FGGT, nous nous engageons à respecter des réglementations qui couvrent spécifiquement l'approvisionnement responsable en métaux précieux, diamants et pierres précieuses sans conflit et à la diligence raisonnable requise au sein de la chaîne d'approvisionnement afin d'assurer la conformité aux politiques et procédures locales et internationales pour une bonne livraison et un bon approvisionnement.<br/><br/>Nous nous engageons à respecter nos plans d'expansion, notre position de leader sur le marché et nos objectifs d'excellence commerciale, que nous atteindrons en continuant à fournir un service client inégalé et les normes de qualité les plus élevées dans tous les aspects de nos opérations."
      },
      commitment:{
        title:'Notre Engagement.',
        text:"FGGT est une entreprise humaine - cela signifie que nous mettons nos fournisseurs, nos partenaires, nos clients et nos employés au centre de tout ce que nous faisons. Notre culture célèbre la diversité et l'inclusion, tout en s'unissant autour d'une mission commune et d'un privilège distinct d'aider notre peuple. Nous fournissons des normes et un engagement uniques à nos partenaires de confiance.<br/><br/>Nous nous efforçons de nous assurer que nos professionnels, nos opportunités de développement personnel et social pour libérer leur plein potentiel et avoir des chances égales d'avancement, chez FGGT, nous entretenons un environnement inclusif envers nos associés de la chaîne d'approvisionnement et nos employés."
      },
      values:{
        title:'Valeurs.',
        text:"Nos gens viennent en premier. Nous nous soutenons et nous apprécions les uns les autres, embrassons les différences, célébrons l'unicité, encourageons le développement et récompensons les performances. <b>Nous transformons notre avenir avec courage et vision en travaillant en équipe.</b><br/><br/>Nous sommes curieux et innovants; nous sommes agiles dans ce que nous faisons et nous nous engageons toujours à gagner en nous concentrant sur les priorités qui font la différence. Nous tenons nos engagements parce que nous sommes personnellement responsables, nous apprenons de nos erreurs et nous nous efforçons de nous améliorer continuellement. Nous sommes dignes de confiance avec la plus grande intégrité.<br/><br/>Nous écoutons et cherchons toujours la vérité ensemble, et la disons telle qu’elle est, même lorsque c’est difficile. La communication est toujours ouverte et claire, et nous nous efforçons de simplifier et d'encourager la collaboration avec honnêteté et respect. Nous sommes ravis de gagner la confiance de nos fournisseurs et partenaires, de développer des relations durables et de fournir la meilleure qualité, service et valeur."
      }
    }
  },
  about:{
    title: 'À Propos de Nous',
    services:{
      title: 'Nos Services.',
      text:'<ul><li>Évaluation.</li><li>Appréciant.</li><li>Planification.</li><li>Découpe et polissage de diamants et pierres précieuses.</li> <li>Manufacture de bijoux.</li></ul>'
    },
    global:{
      title: 'Présence Globale.',
      text:"FGGT a construit une relation solide avec les fournisseurs et partenaires et a montré le savoir-faire et l'expérience nécessaires pour exécuter et exécuter dans le monde entier dans les métaux précieux, les bijoux, les diamants et les pierres précieuses."
    },
    supliers:'POUR LES FOURNISSEURS',
  },
  preciousMetals:{
    title:'Métaux Précieux',
    sourcing:{
      title: 'Sourcing & Trading.',
      text:"L'expérience de FGGT dans le commerce et l'approvisionnement des métaux précieux fait de FGGT une société Excel dans l'application des normes locales et internationales dans les politiques et procédures de conformité et de chaîne d'approvisionnement, l'exportation et l'importation sur les marchés internationaux tels que l'Amérique du Sud, l'Afrique, l'Europe et l'Extrême-Orient. Nos services de trading sur le marché international et notre sécurité font de FGGT un leader unique dans ce que nous faisons en maintenant des relations à long terme avec des exportateurs d'or et des marchands de métaux précieux agréés, situés dans le monde entier pour trouver et acheter des métaux précieux directement auprès des fournisseurs locaux et Les mineurs."
    },
    refining:{
      title:'Raffinage.',
      text:"FGGT est une entreprise intégrée et innovante de métaux précieux qui promeut; L'industrie de l'or, du platine et de l'argent en développant et en soutenant les marchés des produits d'or, de platine et d'argent à travers le monde, en particulier les lingots d'or.",
    },
    doreBars: {
      title:'Lingots.',
      text:"Grâce à notre vaste réseau mondial de filiales d'approvisionnement, complété par nos fournisseurs de confiance avec lesquels nous avons établi des partenariats solides et enrichissants; nous achetons des lingots d'or, de platine et d'argent Dore directement dans les mines elles-mêmes, offrant à nos clients une qualité, une valeur et une cohérence inégalées en matière de produits et de services."
    },
    selling:{
      title:"Vendre de l'or, du platine et / ou de l'argent à Dubaï.",
      text:"Si vous voulez vendre de l'or, vous avez besoin d'un partenaire en qui vous pouvez avoir confiance. La vente d'or est simplement une question de confiance FGGT garantit la transparence lors de l'achat de votre Or.<br/>Nous Achetons Tous Les Articles En Or, Platine Et / Ou Argent. Par Exemple:<br/><ul> <li>Bijoux anciens ou brisés (bijoux ancestraux, ferraille d'or, bijoux en or, en or et en argent cassés): bagues, chaînes, boucles d'oreilles, bracelets, et bien plus encore.</li> <li>Bijoux en diamant, bijoux anciens, bijoux avec pierres ou similaires, bijoux refermables.</li><li>Argenterie et métal argenté (dont couverts en argent).</li><li>Or dentaire.</li><li>Pièces en or et en argent (par ex. Ducats, schilling).</li><li>Lingots d'or et lingots d'argent de tous les fabricants.</li><li></li>Montres de luxe également en acier.<li>Montres en or et montres en argent (également en or cassé).</li><li>Diamants.</li></ul>"
    }
  },
  diamonds:{
    title:'Diamants',
    certified:{
      title:'Diamants en vrac certifiés.',
      text:"FGGT utilise des pratiques de fabrication de pointe, garantissant le respect des normes de santé et de sécurité les plus élevées, tout en gérant la création de valeur des diamants bruts aux diamants polis. Avec l'incorporation de technologies de pointe dans nos procédures avec un personnel hautement expérimenté, transformant chaque diamant brut en un diamant brillant. Notre processus méticuleux de fabrication de diamants à travers nos partenaires implique les processus suivants: planification, tri, calibrage, découpe, polissage et certification."
    },
    fancy:{
      title:'Diamants Fantaisie.',
      text:"Spark and Shine of a Diamond in Colors, la variété la plus unique de diamants fantaisie pour donner à FGGT la valeur ajoutée envers nos clients."
    },
    rough:{
      title:'Diamants Bruts.',
      text:"FGGT est un leader dans l'approvisionnement en diamants bruts durables et conformes (sans conflit, certifié Kimberley Process) provenant de mines de qualité et de fournisseurs de tous les principaux pays producteurs de diamants. En plus de notre vaste réseau existant de fournisseurs, de nouveaux fournisseurs continuent de rejoindre notre réseau de partenaires, ce qui nous permet de diversifier notre portefeuille et leur permet de soutenir leurs objectifs."
    },
    selling:{
      title:'Vente de diamants à Dubaï.',
      text:"Tout comme les métaux précieux, les diamants peuvent également agir comme votre actif précieux, afin de monétariser vos diamants, vous pouvez les vendre aussi bien de l'or, du platine et de l'argent, mais l'obstacle qui se présente est de trouver un acheteur authentique, FGGT est votre acheteur de confiance."
    }
  },
  gemstones:{
    title:'Pierres Précieuses',
    info:"FGGT est connue pour son approvisionnement en pierres précieuses de qualité exceptionnelle et authentique qui montre une brillance et un éclat naturels et qui ne manqueront pas de renforcer le désir de nos clients.<br/><br/>FGGT est bien connu pour l'importation et l'exportation de pierres précieuses extraordinaires brutes ou polies à travers le monde. Nous sommes fiers d'être l'un des leaders dans la gestion complète de la chaîne de valeur minérale avec une vaste expérience pratique et sur le terrain, extrayant les trésors rares des mines d'Amérique latine, d'Asie du Sud et d'Afrique.",
    emeralds:{
      title:'Émeraudes.',
      text:"En tant que l'une des pierres précieuses les plus recherchées, notre intérêt pour l'importation et l'exportation d'émeraudes brutes et polies et la forte demande sur le marché font de FGGT votre partenaire de la chaîne d'approvisionnement pour garantir la meilleure qualité d'émeraudes."
    },
    selling:{
      title:'Vente de pierres précieuses à Dubaï.',
      text:"Tout comme les métaux précieux, les pierres précieuses peuvent également agir comme votre atout précieux, afin de monétariser vos pierres précieuses, vous pouvez les vendre ainsi que des diamants, mais l'obstacle qui se présente est de trouver un acheteur authentique, FGGT est votre acheteur de confiance."
    }
  },
  compliance:{
    title: 'Conformité',
    diligence: {
      title: 'Vérifications Nécessaires.',
      text:"FGGT démontre l'application et la conformité aux normes de sécurité de la chaîne d'approvisionnement locales et internationales où les acheteurs de métaux précieux et de pierres précieuses doivent évaluer en permanence leur chaîne d'approvisionnement pour identifier, atténuer et éliminer les risques de sécurité potentiels et surveiller la logistique des produits."
    },
    policies:{
      title:'Politiques et Procédures.',
      text:"FGGT garantit le respect des normes et politiques de conformité strictes qui doivent respecter et être appliquées par les fournisseurs, les investisseurs externes, les clients et les associés pour s'engager dans l'une des activités commerciales avec l'entreprise qui sont les suivantes: <br/><br/><div className='list'><ul><li>SUPPLY CHAIN DUE DILIGENCE POLICY FGGT 2021. <a href='./assets/documents/WEB SUPPLY CHAIN DUE DILIGENCE POLICY FGGT Template .pdf' target='_blank'>Voir la documentation ici.</a></li> <li>AML MANUAL FOR POLICIES & PROCEDURES FGGT 2021. <a href='./assets/documents/WEB AML MANUAL FOR POLICIES & PROCEDURES FGGT Template .pdf' target='_blank'>Voir la documentation ici.</a></li> <li>AML POLICY COMPLIANCE & SUPERVISORY PROCEDURES FGGT 2021. <a href='./assets/documents/WEB AML POLICY COMPLIANCE & SUPERVISORY PROCEDURES FGGT Template .pdf' target='_blank'>Voir la documentation ici.</a></li><li>CONFLICT FREE GOLD STANDARD PRECIOUS METALS SOURCING  FGGT 2021. <a href='./assets/documents/WEB CONFLICT FREE GOLD STANDARD PRECIOUS METALS SOURCING  FGGT Template .pdf' target='_blank'>Voir la documentation ici.</a></li></ul></div>"
    },
    international:{
      title:"Métaux précieux internationaux - Diamants - Politiques et procédures relatives aux pierres précieuses pour le commerce et l'approvisionnement.",
      text:"FGGT adhère non seulement à ses propres normes et politiques en place; mais adhère également et veille à ce que toutes les activités soient menées conformément aux normes et politiques définies par les organes directeurs internationaux et mondiaux, qui sont les suivantes:"
    }

  },
  jewerly:{
    title:'Bijoux',
    design:{
      'title': 'Entrez dans le monde de la mode et du design pour votre propre collection de bijoux avec FGGT.',
      'text':"Les bijoux peuvent être fabriqués à partir d'une large gamme de matériaux. Les diamants et les pierres précieuses et les matériaux similaires tels que l'ambre et le corail, les métaux précieux, les perles et les coquillages ont été largement utilisés, et l'émail a souvent été important. Dans la plupart des cultures, les bijoux peuvent être considérés comme un symbole de statut, pour ses propriétés matérielles, ses motifs ou pour des symboles significatifs. Les bijoux ont été conçus pour orner presque tout le monde, des épingles à cheveux aux bagues d'orteils."
    },
    hight:{
      title:'Haute Joaillerie',
      text:"La passion FGGT a animé ses créations de Haute Joaillerie. FGGT partage sa vision unique de l'artisanat, marquée par des sources d'inspiration enchanteresses et un savoir-faire vertueux. Chaque pierre minutieusement sélectionnée est choisie pour sa qualité exceptionnelle et l'émotion qu'elle porte.<br/><br/><span>Chez FGGT, nous pouvons créer le bijou de vos rêves avec l'unique et unique en son genre.</span>"
    }
  },
  contact: {
    title:'Nous Contacter',
    form: {
      name:'Nom',
      surname:'Nom de famille',
      email:'Email',
      phone: 'Numéro de Téléphone',
      cancel:'Annuler',
      send:'Envoyer',
      carrers:'Carrers'
    }
  },
  footer: {
    call: 'CALL US',
    write:'WRITE US',
    visit: 'VISIT US',
    business: 'BUSINESS HOURS',
    open:'Sunday - Thursday',
    closeDays: 'Friday and Saturday',
    close:'Closed',
    navigation:'NAVIGATION'

  },
  callToAction: {
    title: 'Devenez un Fournisseur FGGT.',
    metals: 'Fournisseur De Métaux précieux',
    diamonds: 'Diamants et Pierres Précieuses.',
    newClient:'Inscrivez-vous en tant que client.',
    button:'Appliquer Maintenant'
  },
  logistic: {
    title: 'Logistique',
    text: "Chez FGGT, nous nous engageons à maintenir des normes de livraison inégalées. Que ce soit; Des expéditions quotidiennes, hebdomadaires, mensuelles ou à la demande de métaux précieux, de diamants et de pierres précieuses que nous livrons 24 heures sur 24 grâce à nos partenariats stratégiques avec les principaux fournisseurs de services logistiques. Toutes les opérations logistiques de FGGT sont menées conformément aux exigences et politiques internationales, et nous les respectons strictement.<br/> <br/>Les partenariats de FGGT avec les principaux fournisseurs de logistique nous aident à accélérer toutes les expéditions, ce qui augmente l'efficacité de nos processus d'import-export en conformité avec les autorités locales et internationales.",
    transport: 'Nous travaillons main dans la main avec les compagnies <br/> de transport de sécurité les plus réputées.'
  }

}
/* eslint-disable default-case */
export const validate = (rules, value, schema) => {
  const res = rules.map(rule => {
    switch (rule) {
      case 'String':
        return typeof (value) === 'string';
      case 'Int':
        return typeof (value) === 'number';
      case 'Boolean':
        return typeof (value) === 'boolean';
      case 'Bigint':
        return typeof (value) === 'bigint';
      case 'Require':
        let isInt = true;
        if (typeof (value) === 'number') {
          isInt = !isNaN(value);
        }
        console.log((value !== '' && value !== null && value !== undefined && isInt))
        return (value !== '' && value !== null && value !== undefined && isInt);
      case 'Email':
        return validateEmail(value);
      case 'ArrayString':
        return arrayString(value, schema);
      default:
        return true;
    }
  });
  let valido = true;
  res.forEach(e => {
    if (!e) valido = false;
  });
  return valido;
}

const arrayString = (value, schema) => {
  const datos = JSON.parse(value);
  const keys = Object.keys(schema);
  const res = datos.map(dato => keys.map(key => validate(schema[key].split('|'), dato[key], null)));
  let valido = true;
  res.forEach(e => {
    if (!e) valido = false;
  });
  return valido;
}

const validateEmail = email => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}